// Projects.js
import React, { useEffect, useState } from "react";
import { Button, Card, Container, Nav, Tab } from "react-bootstrap";
import CarouselModal from "./util/CarouselModal";
import projectsData from './util/ProjectData';


function Projects() {
  useEffect(() => {
    document.title = "ΤΑ ΕΡΓΑ ΜΑΣ";
  }, []);

  const singleFamilyHomesProjects = projectsData.singleFamilyHomes;
  const apartmentBuildingsProjects = projectsData.apartmentBuildings;
  const commercialBuildings =projectsData.commercialBuildings;
  const publicInterestBuildings = projectsData.publicInterestBuildings;

  const projectKeys = ["Μονοκατοικίες", "Πολυκατοικίες", "Επαγγελματικά Κτήρια", "Κτήρια Δήμοσιου Ενδιαφέροντος"]


  const [modalState, setModalState] = useState({});

  const openModal = (projectId) => {
    setModalState({ ...modalState, [projectId]: true });
  };

  const closeModal = (projectId) => {
    setModalState({ ...modalState, [projectId]: false });
  };


  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (index) => {
    setSelectedTab(index);
  };

  const handlePrevTab = () => {
    if (selectedTab > 0) {
      setSelectedTab(selectedTab - 1);
    }
  };

  const handleNextTab = () => {
    if (selectedTab < projectKeys.length) {
      setSelectedTab(selectedTab + 1);
    }
  };


  const [visibleTabs, setVisibleTabs] = useState(projectKeys.length);

  const handleResize = () => {
    const screenWidth = window.innerWidth;

    // Define breakpoints and the number of tabs to show at each breakpoint
    const breakpoints = [
      { width: 550, tabs: 2 },   // Show 2 tabs on screens < 576px wide
      { width: 800, tabs: 3 },   // Show 3 tabs on screens >= 576px and < 768px wide
      { width: 1000, tabs: 4 },   // Show 4 tabs on screens >= 768px and < 992px wide
    ];

    // Determine the number of tabs to show based on the screen width
    const newVisibleTabs = breakpoints.reduce((tabsToShow, breakpoint) => {
      if (screenWidth >= breakpoint.width) {
        return breakpoint.tabs;
      }
      if (screenWidth < 550) {
        return 1
      }
      return tabsToShow;
    }, 4);

    setVisibleTabs(newVisibleTabs);

  };


  useEffect(() => {
    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Initial calculation of visible tabs
    handleResize();

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);



  return (
    <div>
      <Container className="mt-5 mb-5">
        <Tab.Container activeKey={selectedTab.toString()} onSelect={(key) => handleTabChange(parseInt(key))} >
          <Nav style={{ width: '100%' }} variant="tabs" className="nav-fill" fill>

            <div className="tab-container">

              <button className="modern-arrow-button-left" onClick={handlePrevTab} disabled={selectedTab === 0}></button>

              {projectKeys.map((item, index) => {
                let availableTabsBefore = Math.min(selectedTab, visibleTabs - 1);
                const availableTabsAfter = (projectKeys.length - selectedTab - 1) % visibleTabs;

                const tabsToShow = visibleTabs; // Set the fixed number of tabs to show
                let tabsBeforeSelected = Math.min(Math.floor(tabsToShow / 2), availableTabsBefore);
                let tabsAfterSelected = Math.min(Math.floor(tabsToShow / 2), availableTabsAfter);

                if (tabsBeforeSelected + tabsAfterSelected < tabsToShow - 1) {
                  const tabsToAdd = tabsToShow - tabsBeforeSelected - tabsAfterSelected - 1;

                  if (tabsToAdd + tabsBeforeSelected <= availableTabsBefore) {
                    tabsBeforeSelected += tabsToAdd;
                  } else {
                    let tabsToAdd = availableTabsBefore - tabsBeforeSelected
                    tabsBeforeSelected += tabsToAdd;
                    availableTabsBefore = 0
                  }
                  if (tabsBeforeSelected + tabsAfterSelected < tabsToShow - 1) {
                    tabsAfterSelected += tabsToAdd - availableTabsBefore;
                  }
                }


                const isActive = index === selectedTab;


                // Ensure that tabsToShow does not exceed the total number of available tabs



                const isBeforeSelected = index < selectedTab && index >= selectedTab - tabsBeforeSelected;
                const isAfterSelected = index > selectedTab && index <= selectedTab + tabsAfterSelected;
                return (
                  <div
                    className={` ${isActive || isBeforeSelected || isAfterSelected ? '' : 'hidden'}`}
                    key={`tabs-${index}-${item.name}`}
                    onClick={() => handleTabChange(index)}
                  >                      <div className={`tab-content   `}>

                      <div style={{ flex: 1 }}>
                        <Nav.Item style={{ width: "100%" }}>
                          <Nav.Link
                            className="text-dark"
                            eventKey={index}
                          >
                            <div style={{ padding: "10px" }}>{item}</div>
                          </Nav.Link>
                        </Nav.Item>
                      </div>
                    </div>
                  </div>
                )
              })}
              <button className="modern-arrow-button-right" onClick={handleNextTab} disabled={selectedTab === projectKeys.length - 1}></button>

            </div>
          </Nav>

          <Tab.Content style={{ padding: 10 }}>
            <Tab.Pane eventKey={0}>
              <div className="row mb-5 mt-3" >
                <div>
                  <span style={{ fontSize: '25px', width: '100%', display: 'flex', justifyContent: 'center', textShadow: '2px 2px 4px rgba(12, 51, 72, 0.3)', color: '#0c3348' }}>
                    Κατασκευάζουμε ευρύχωρες μονοκατοικίες με πρωτοποριακό σχεδιασμό.
                  </span>
                </div>
              </div>
              <div className="row">
                {singleFamilyHomesProjects && singleFamilyHomesProjects.map((item) => <>
                  <div className="col-xl-3 col-md-4 col-sm-6 d-flex justify-content-center">
                    <Card
                      style={{
                        minHeight: "500px",
                        marginBottom: 10,
                        width: "278px",
                        padding: "20px",
                        border: 'none',
                        boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                      }}
                    >
                      <Card.Img
                        variant="top"
                        src={item.imageUrl}
                        style={{
                          height: "173px",
                          cursor: "pointer",
                          borderRadius: "10px",
                          transition: "transform 0.2s",
                        }}
                        onMouseOver={(e) =>
                          (e.target.style.transform = "scale(1.05)")
                        }
                        onMouseOut={(e) =>
                          (e.target.style.transform = "scale(1)")
                        }
                      />

                      <Card.Body
                        style={{ marginTop: "10px", textAlign: "center" }}
                      >
                        <div style={{ minHeight: 200, }}>
                        <Card.Title style={{ color: "#1f334b" , minHeight: '80px'   }}>
                            {item.title}
                          </Card.Title>
                          <Card.Text className="mb-3 service-description" >
                            {item.description}
                          </Card.Text>
                        </div>
                        <div className="d-flex justify-content-center">
                        <div style={{ position: "absolute", bottom: "20px", }}>
                          <Button className="more" variant="outline-dark" onClick={() => openModal(item.id)}>
                            Περισσότερα
                          </Button>
                        </div>
                        </div>
                      </Card.Body>
                    </Card>
                    <CarouselModal
                      title={item.title}
                      content={item.description}
                      showModal={modalState[item.id]}
                      closeModal={() => closeModal(item.id)}
                      carouselData={item.carouselData}
                    />
                  </div>
                </>)}
              </div>

              {/* <div className="col-md-4">
                  <Card
                    style={{
                      minHeight: "500px",
                      width: "20rem",
                      padding: "20px",
                      border: 'none',
                      boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                    }}
                  >
                    <Card.Img
                      variant="top"
                      src="aktaio2.jpg"
                      style={{
                        width: "278px",
                        height: "173px",
                        cursor: "pointer",
                        borderRadius: "10px",
                        transition: "transform 0.2s",
                      }}
                      onMouseOver={(e) =>
                        (e.target.style.transform = "scale(1.05)")
                      }
                      onMouseOut={(e) =>
                        (e.target.style.transform = "scale(1)")
                      }
                    />

                    <Card.Body
                      style={{ marginTop: "15px", textAlign: "center" }}
                    >
                      <div style={{ minHeight: 200, paddingTop: "10px" }}>
                        <Card.Title style={{ color: "#1f334b" }}>
                            Συγκρότημα 3 ανεξάρτητων μονοκατοικιών στον Άγιο Βασίλειο Ρίου{" "}
                        </Card.Title>
                        <Card.Text>
                          Κατοικία σε 2 επίπεδα στο Ρίο Πατρών.
                        </Card.Text>
                      </div>
                      <Button className="more" variant="outline-dark">
                        Περισσότερα
                      </Button>
                    </Card.Body>
                  </Card>
                </div>

                <div className="col-md-4">
                  <Card
                   style={{
                    minHeight: "500px",
                    width: "20rem",
                    padding: "20px",
                    border: 'none',
                    boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                  }}
                >
                  
                    <Card.Img
                      variant="top"
                      src="eglykada1.jpg"
                      style={{
                        width: "278px",
                        height: "173px",
                        cursor: "pointer",
                        borderRadius: "10px",
                        transition: "transform 0.2s",
                      }}
                      onMouseOver={(e) =>
                        (e.target.style.transform = "scale(1.05)")
                      }
                      onMouseOut={(e) =>
                        (e.target.style.transform = "scale(1)")
                      }
                    />

                    <Card.Body
                      style={{ marginTop: "15px", textAlign: "center" }}
                    >
                      <div style={{ minHeight: 200 }}>
                        <Card.Title style={{ color: "#1f334b" }}>
                          Διπλοκατοικία στην Εγλυκάδα Πάτρα
                        </Card.Title>
                        <Card.Text>
                          Διόροφο δείγμα τυπικής διπλοκατοικίας στην Εγλυκάδα.
                        </Card.Text>
                      </div>

                      <Button className="more" variant="outline-dark">
                        Περισσότερα
                      </Button>
                    </Card.Body>
                  </Card>
                </div>
              </div> */}
            </Tab.Pane>
            <Tab.Pane eventKey={1}>
              <div className="row mb-5 mt-3" >
                <div className="d-flex justify-content-center">
                  <span style={{ fontSize: '25px', width: '100%', display: 'flex', justifyContent: 'center', textShadow: '2px 2px 4px rgba(12, 51, 72, 0.3)', color: '#0c3348' }}>
                  Κατασκευάζουμε πολυκατοικίες σύμφωνα με τις απαιτήσεις της σύγχρονης κοινωνίας.
                  </span>
                </div>
              </div>
              <div className="row">
              {apartmentBuildingsProjects && apartmentBuildingsProjects.map((item) => <>
                  <div className="col-xl-3 col-md-4 col-sm-6 d-flex justify-content-center">
                    <Card
                      style={{
                        minHeight: "500px",
                        marginBottom: 10,
                        width: "278px",
                        padding: "20px",
                        border: 'none',
                        boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                      }}
                    >
                      <Card.Img
                        variant="top"
                        src={item.imageUrl}
                        style={{
                          height: "173px",
                          cursor: "pointer",
                          borderRadius: "10px",
                          transition: "transform 0.2s",
                        }}
                        onMouseOver={(e) =>
                          (e.target.style.transform = "scale(1.05)")
                        }
                        onMouseOut={(e) =>
                          (e.target.style.transform = "scale(1)")
                        }
                      />

                      <Card.Body
                        style={{ marginTop: "15px", textAlign: "center" }}
                      >
                        <div style={{ minHeight: 200, paddingTop: "10px" }}>
                          <Card.Title style={{ color: "#1f334b" , minHeight: '80px' }}>
                            {item.title}
                          </Card.Title>
                          <Card.Text className="mb-3 service-description" style={{ height: '115px'}}>
                            {item.description}
                          </Card.Text>
                        </div>
                        <div className="d-flex justify-content-center">
                        <div style={{ position: "absolute", bottom: "20px", }}>
                          <Button className="more" variant="outline-dark" onClick={() => openModal(item.id)}>
                            Περισσότερα
                          </Button>
                        </div>
                        </div>

                      </Card.Body>
                    </Card>
                    <CarouselModal
                      title={item.title}
                      content={item.description}
                      showModal={modalState[item.id]}
                      closeModal={() => closeModal(item.id)}
                      carouselData={item.carouselData}
                    />
                  </div>
                </>)}
              
              </div>

            </Tab.Pane>
            <Tab.Pane eventKey={2}>
              <div className="row mb-5 mt-3" >
                <div>
                  <span style={{ fontSize: '25px', width: '100%', display: 'flex', justifyContent: 'center', textShadow: '2px 2px 4px rgba(12, 51, 72, 0.3)', color: '#0c3348' }}>
                  Κατασκευάζουμε επαγγελματικά κτήρια με γνώμονα την άρτια λειτουργικότητα της επιχείρησης σας.
                  </span>
                </div>
              </div>
              <div className="row">
              {commercialBuildings && commercialBuildings.map((item) => <>
                  <div className="col-xl-3 col-md-4 col-sm-6 d-flex justify-content-center">
                    <Card
                      style={{
                        minHeight: "500px",
                        marginBottom: 10,
                        width: "278px",
                        padding: "20px",
                        border: 'none',
                        boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                      }}
                    >
                      <Card.Img
                        variant="top"
                        src={item.imageUrl}
                        style={{
                          height: "173px",
                          cursor: "pointer",
                          borderRadius: "10px",
                          transition: "transform 0.2s",
                        }}
                        onMouseOver={(e) =>
                          (e.target.style.transform = "scale(1.05)")
                        }
                        onMouseOut={(e) =>
                          (e.target.style.transform = "scale(1)")
                        }
                      />

                      <Card.Body
                        style={{ marginTop: "15px", textAlign: "center" }}
                      >
                        <div style={{ minHeight: 200, paddingTop: "10px" }}>
                          <Card.Title style={{ color: "#1f334b" , minHeight: '80px' }}>
                            {item.title}
                          </Card.Title>
                          <Card.Text className="mb-3 service-description" style={{ height: '115px'}}>
                            {item.description}
                          </Card.Text>
                        </div>
                        <div className="d-flex justify-content-center">
                        <div style={{ position: "absolute", bottom: "20px", }}>
                          <Button className="more" variant="outline-dark" onClick={() => openModal(item.id)}>
                            Περισσότερα
                          </Button>
                        </div>
                        </div>

                      </Card.Body>
                    </Card>
                    <CarouselModal
                      title={item.title}
                      content={item.description}
                      showModal={modalState[item.id]}
                      closeModal={() => closeModal(item.id)}
                      carouselData={item.carouselData}
                    />
                  </div>
                </>)}
              
              </div>

            </Tab.Pane>
            <Tab.Pane eventKey={3}>
              <div className="row mb-5 mt-3" >
                <div>
                  <span style={{ fontSize: '25px', width: '100%', display: 'flex', justifyContent: 'center', textShadow: '2px 2px 4px rgba(12, 51, 72, 0.3)', color: '#0c3348' }}>
                    Διαθέτουμε πολυετή εμπειρία σε κατασκευές Κτηρίων Δημόσιου Ενδιαφέροντος.
                  </span>
                </div>
              </div>
              <div className="row">
              {publicInterestBuildings && publicInterestBuildings.map((item) => <>
                  <div key={`${item.title}`} className="col-xl-3 col-md-4 col-sm-6 d-flex justify-content-center">
                    <Card
                      style={{
                        minHeight: "500px",
                        marginBottom: 10,
                        width: "278px",
                        padding: "20px",
                        border: 'none',
                        boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                      }}
                    >
                      <Card.Img
                        variant="top"
                        src={item.imageUrl}
                        style={{
                          height: "173px",
                          cursor: "pointer",
                          borderRadius: "10px",
                          transition: "transform 0.2s",
                        }}
                        onMouseOver={(e) =>
                          (e.target.style.transform = "scale(1.05)")
                        }
                        onMouseOut={(e) =>
                          (e.target.style.transform = "scale(1)")
                        }
                      />

                      <Card.Body
                        style={{ marginTop: "15px", textAlign: "center" }}
                      >
                        <div style={{ minHeight: 200, paddingTop: "10px" }}>
                          <Card.Title style={{ color: "#1f334b" , minHeight: '80px' }}>
                            {item.title}
                          </Card.Title>
                          <Card.Text className="mb-3 service-description" style={{ height: '115px'}}>
                            {item.description}
                          </Card.Text>
                        </div>
                        <div className="d-flex justify-content-center">
                        <div style={{ position: "absolute", bottom: "20px", }}>
                          <Button className="more" variant="outline-dark" onClick={() => openModal(item.id)}>
                            Περισσότερα
                          </Button>
                        </div>
                        </div>

                      </Card.Body>
                    </Card>
                    <CarouselModal
                      title={item.title}
                      content={item.description}
                      showModal={modalState[item.id]}
                      closeModal={() => closeModal(item.id)}
                      carouselData={item.carouselData}
                    />
                  </div>
                </>)}
              
              </div>

            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </Container>
      <CarouselModal

      />
    </div >
  );
}

export default Projects;
