import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import { Container, Row, Col, Button } from 'react-bootstrap';
import CarouselItem from './util/CarouselItem';
import Progressbar from './util/Progressbar';

function Dashboard() {
  useEffect(() => {
    document.title = 'ΑΡΧΙΚΗ';
  }, []);
  const carouselData = [
    { imageSrc: 'carousel2.jpg', caption: 'Ολοκληρωμένες τεχνικές και συμβουλευτικές υπηρεσίες' },
    { imageSrc: 'arxikinew.jpg', caption: 'Υπηρεσίες εκπόνησης μελετών' },
    { imageSrc: 'carousel3.jpg', caption: 'Υπηρεσίες κατασκευών και επίβλεψης' },

  ]
  
  return (
    <>
      <div style={{  margin: '20px'}} >
        <CarouselItem data={carouselData} />
      </div>


      <Container  className="container-with-margin">
        <Row>
          <Col md={6}>
            <h2 style={{ fontSize: 36 }} className="theme-color">ΤΕΧΝΙΚΗ ΕΤΑΙΡΙΑ ΥΙΟΙ Κ. ΚΑΟΥΡΗ O.E.</h2>
            <div style={{ marginTop: '20px' }}>
              <p style={{fontSize: '18px' }}>
                Μετρώντας τέσσερις δεκαετίες λειτουργίας, η εταιρία μας συνιστά μια αξιόπιστη παρουσία στον κλάδο της οικοδομικής κατασκευής και μελέτης. Αναλαμβάνουμε την εκτέλεση
                μελετών και την κατασκευή υψηλής ποιότητας κατοικιών και επαγγελματικών
                χώρων (γραφεία, εμπορικές εκθέσεις, εργοστάσια, καταστήματα κ.α.), καθώς και ανακαινίσεις, αναπαλαιώσεις διατηρητέων κτιρίων, επισκευές και μεταλλικές κατασκευές.
              </p>
              <p style={{ fontSize: '18px' }}>
                Η τεχνογνωσία μας μας επιτρέπει να προσφέρουμε καινοτόμες τεχνικές λύσεις, ενώ
                η πολυετής εμπειρία μας στο χώρο εγγυάται την ποιότητα και αξιοπιστία των υπηρεσιών μας.
              </p>
              <p style={{ fontSize: '18px' }}>
                Η ποιότητα είναι για εμάς προτεραιότητα, και η ικανοποίηση των πελατών μας είναι κινητήριος
                δύναμη για να συνεχίσουμε να αναπτυσσόμαστε και να αντιπροσωπεύουμε το υψηλότερο επίπεδο επαγγελματισμού.
              </p>
            </div>
          </Col>
          <Col md={6}>
            <img src="dashBoard.jpeg" alt="Company" className="img-fluid" />
          </Col>
        </Row>
      </Container>

      <div className='bg-light-subtle bg-gradient' >

        <div style={{ display: 'flex', justifyContent: 'center', marginLeft: '5%', marginRight: '5%', width: '90%' }} >
          <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', width: '80%' }}>
            <Progressbar value={40} textNum={40} text="Χρόνια στον κλάδo" />
            <Progressbar value={87} textNum={700} text="Ολοκληρωμένες μελετές και κατασκευές" />
            <Progressbar value={55} textNum={46} text="Περιοχές" />
            <Progressbar value={35} textNum={35} text="Μελετητές και επιστημονικοί συνεργάτες" />
          </div>
        </div>
      </div>

      {/* <Container style={{ margin: 0, padding: 0 }}>
        <div className='bg-light-subtle bg-gradient' style={{ position: 'relative', width: '100vw', textAlign: 'center' }}>
          <h3 className="theme-color"> ΕΧΕΤΕ ΚΑΠΟΙΟ</h3>
          <h2 className="theme-color"> ΚΑΤΑΣΚΕΥΑΣΤΙΚΟ ΕΡΓΟ</h2>
          <h3 className="theme-color"> ΓΙΑ ΥΛΟΠΟΙΗΣΗ;</h3>
            <Button
             as={Link}
             to="/contact"
              variant="primary"
              style={{
                // Adjust the button's width
                height: '40px', // Adjust the button's height
                zIndex: 1, // Ensure the button appears above the image
                marginTop: '20px',
                marginBottom: '20px',
                border: 'none',
                backgroundColor: '#0c3348'
              }}
            >
              ΖΗΤΗΣΤΕ ΠΡΟΣΦΟΡΑ
            </Button>
          </div>
       
      </Container> */}




    </>
  );
}

export default Dashboard;
