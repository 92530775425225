import React from 'react';
import  { createRoot } from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import 'react-circular-progressbar/dist/styles.css';
import './index.css'; // You might have other styles
import AppRouter from './main';

const root = createRoot(document.getElementById('root'));

root.render(<AppRouter />);