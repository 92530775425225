// routes.js
import React from 'react';
import AboutUs from './components/AboutUs';
import Contact from './components/Contact';
import Dashboard from './components/Dashboard';
import Projects from './components/Projects';
import Services from './components/Services';


export const routes = [
  {
    path: '/',
    element: <Dashboard />,
  },
  {
    path: '/about',
    element: <AboutUs />,
  },
  {
    path: '/services',
    element: <Services />,
  },
  {
    path: '/projects',
    element: <Projects />,
  },
  {
    path: '/contact',
    element: <Contact />,
  },
];