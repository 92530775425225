import React, { useEffect, useState } from "react";
import GoogleMap from "./GoogleMap";
import ReCAPTCHA from "react-google-recaptcha";
import { Toast } from "react-bootstrap";

function ContactUs() {
  useEffect(() => {
    document.title = "ΕΠΙΚΟΙΝΩΝΙΑ";
  }, []);

  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [notification, setNotification] = useState(null);
  const [showA, setShowA] = useState(true);
  const toggleShowA = () => setShowA(false);

  const showNotification = (type, message) => {
    setNotification({ type, message });
    setTimeout(() => {
      setNotification(null);
    }, 30000); // Hide the notification after 30 seconds
  };

  const handleRecaptchaChange = (value) => {
    setRecaptchaValue(value);
  };

  const clearData = () => {
    setFormData({
      name: "",
      email: "",
      subject: "",
      message: "",
  })
  };

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Send email using the sendEmail function or API endpoint here
    // Replace with your email sending logic
    if (
      formData.name &&
      formData.email &&
      formData.message &&
      formData.subject
    ) {
      try {
        const response = await fetch(
          "https://us-central1-kaourismailer.cloudfunctions.net/sendEmail",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
          }
        );

        if (response.ok) {
          showNotification("success", "Email sent successfully.");
          clearData()

        } else {
          showNotification(
            "error",
            "Email not sent. Please contact us via phone"
          );
        }
      } catch (error) {
        console.error("An error occurred:", error);
        showNotification(
          "error",
          "Email not sent. Please contact us via phone"
        );
      }
    } else {
      showNotification("Light", "Please fill all fields.");
    }

  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-6">
          <div className="contact-info bg-light-subtle bg-gradient p-3">
            <h2 className="theme-color text-center">ΣΤΟΙΧΕΙΑ ΕΠΙΚΟΙΝΩΝΙΑΣ</h2>
            <p
              style={{
                fontStyle: "italic",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              Είμαστε στη διάθεσή σας για οποιαδήποτε πληροφορια.
            </p>
            <p>
              <strong>Διεύθυνση:</strong> Καρατζά 41, Πάτρα 26 223
            </p>
            <p>
              <strong>Email:</strong> info@kaouris.gr
            </p>
            <p>
              <strong>Τηλέφωνο:</strong> 2610 313158 , 2610 337980 
            </p>
          </div>
          {/* Square-shaped Map */}
          <div className="map-container">
            <GoogleMap />
          </div>
        </div>

        <div className="col-md-6">
          <div className="communication-form bg-light-subtle p-3">
            <h2 className="theme-color text-center">ΦΟΡΜΑ ΕΠΙΚΟΙΝΩΝΙΑΣ</h2>
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  Ονοματεπώνυμο:
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  name="name"
                  onChange={handleChange}
                  value={formData.name}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  Email:
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  onChange={handleChange}
                  value={formData.email}
                  name="email"
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="subject" className="form-label">
                  Θέμα:
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="subject"
                  onChange={handleChange}
                  value={formData.subject}
                  name="subject"
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="message" className="form-label">
                  Μήνυμα:
                </label>
                <textarea
                  className="form-control"
                  id="message"
                  name="message"
                  onChange={handleChange}
                  value={formData.message}
                  rows="4"
                  required
                ></textarea>
              </div>
              <div className="mb-3">
                <ReCAPTCHA
                  sitekey="6Ld1QvonAAAAAFXTaxwonucf45wvd9ZYYLur8CbC"
                  onChange={handleRecaptchaChange}
                />
              </div>
              <button
                disabled={!recaptchaValue}
                type="submit"
                className="btn btn-primary"
              >
                Υποβολή
              </button>
            </form>

            {notification && (
              <Toast bg={notification.type} style={{ marginTop: 10 }} show={showA} onClose={toggleShowA}>
                <Toast.Header >
                  <img className="rounded me-2" alt="" />
                  <strong className="me-auto">Email Info</strong>
                  <small>{new Date().toLocaleTimeString()}</small>
                </Toast.Header>
                <Toast.Body>{notification.message}</Toast.Body>
              </Toast>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
