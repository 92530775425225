// Router.js
import React, { useEffect } from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import Footer from './components/Footer';
import './App.css'; // You might have other styles
import NavbarMenu from './components/Navbar';
import { routes } from './routes';
import ReactGA from 'react-ga';

// Load environment variables



// Track a page view

function AppRouter() {

    useEffect(() => {
        // Initialize Google Analytics
        ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);
        // Track a page view
        ReactGA.pageview(window.location);
      }, []);
    
    return (
        <Router >
            <div className="d-flex flex-column min-vh-100">
                <NavbarMenu />
                <div className="flex-grow-1">
                    <Routes>
                        {routes.map((route, index) => (
                            <Route
                                key={index}
                                path={route.path}
                                element={route.element}
                            />
                        ))}
                    </Routes>
                </div>
                <Footer />
            </div>
        </Router>
    );
}

export default AppRouter;
