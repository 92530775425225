import React, { useEffect, useState } from "react";
import { Container, Row, Col, Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

function AboutUs() {
  useEffect(() => {
    document.title = "ΠΟΙΟΙ ΕΙΜΑΣΤΕ";
  }, []);

  const [showModal, setShowModal] = useState(false);
  const [selectedPerson, setSelectedPerson] = useState({});

  const handleOpenModal = (personData) => {
    setSelectedPerson(personData);
    setShowModal(true);
  };

  return (
    <Container className="container-with-margin">
      <Row>
        <div>
          <h1 className="theme-color" style={{ textAlign: "center" }}>
            Η ΕΤΑΙΡΙΑ
          </h1>
          <div style={{ marginTop: "20px" }}>
            <p>
              Η εταιρία μας αναλαμβάνει την μελέτη, σχεδιασμό και κατασκευή
              ιδιωτικών και επαγγελματικών χώρων σε μια ευρεία γκάμα,
              περιλαμβανομένων καταστημάτων, εμπορικών εκθέσεων, εργοστασίων,
              επισκευών κτιρίων, αναπαλαιώσεων διατηρητέων κτιρίων, μεταλλικών
              κτιρίων και εκκλησιαστικών κτιρίων. Ο συνδυασμός της πολυετούς
              εμπειρίας μας και της δυναμικής μας παρουσίας καθιστούν την
              εταιρία μας πρωτοπόρα και αξιόπιστη στον χώρο της κατασκευής.
              Έχουμε κερδίσει την εμπιστοσύνη και την απόλυτη ικανοποίηση των
              πελατών μας, και η αφοσίωσή μας στην υψηλή ποιότητα και την
              αισθητική διακόσμηση καθώς και στην τεχνολογική πρόοδο καθιστούν
              την εταιρία μας πρωταγωνίστρια στον χώρο της κατασκευής.
            </p>
          </div>
        </div>
      </Row>
      <Row style={{ marginBottom: 100}}>
        <div>
          <h1 className="theme-color " style={{ textAlign: "center" }}>
            Η ΕΞΕΛΙΞΗ
          </h1>
          <div style={{ marginTop: "20px" }}>
            <p>
              Το 1964, ο Κωνσταντίνος Καούρης ιδρύει την ομώνυμη τεχνική
              εταιρία, ανοίγοντας νέους δρόμους στον τομέα της κατασκευής. Σε μια περίοδο ιστορικής σημασίας για την πόλη των Πατρών,
               αυτή της επέκτασης και οικοδόμησης της,
               ο Κωνσταντίνος Καούρης αναδείχθηκε ως ένας από
              τους πρωτοπόρους στον χώρο της κατασκευής πολυκατοικιών.
            </p>
            <p>

              Το 1992, την εταιρία αναλαμβάνουν οι Θανάσης & Γιώργος Καούρης, πολιτικοί μηχανικοί,
              υιοί του Κ. Καούρη, φέρνοντας μια εξαιρετικά μοντέρνα προσέγγιση 
              στον τομέα της κατασκευής. Η εταιρία μας έχει αποκτήσει διακριτική φήμη 
              χάρη στις υψηλής ποιότητας κατασκευές μας, την άριστη μελέτη και επίβλεψη
               των έργων μας,
              καθώς και την άψογη αισθητική που διακρίνει τα έργα μας.
            </p>
          </div>
        </div>
      </Row>
      {/* <h1 className="theme-color " style={{ textAlign: "center" }}>
        ΕΜΕΙΣ
      </h1>
      <Row style={{ backgroundColor: "#b1c1da", marginTop: 20 }}>
        <Col
          md={6}
          className="d-flex justify-content-center align-items-center mt-4 mb-4"
        >
          <div>
            <img
              src="sakis.jpg"
              alt="Σάκης Καούρης"
              className="img-fluid"
              style={{ width: "400px", height: "500px", cursor: 'pointer' }}
              onClick={() =>
                handleOpenModal({
                  name: "ΘΑΝΑΣΗΣ ΚΑΟΥΡΗΣ",
                  image: "sakis.jpg",
                  occupation: "ΠΟΛΙΤΙΚΟΣ ΜΗΧΑΝΙΚΟΣ τ.ε.",
                })
              }
            />
            <h4 style={{ color: "white", marginTop: "10px" }}>
              ΘΑΝΑΣΗΣ ΚΑΟΥΡΗΣ
            </h4>
            <h4 style={{ color: "white" }}>ΠΟΛΙΤΙΚΟΣ ΜΗΧΑΝΙΚΟΣ </h4>
          </div>
        </Col>

        <Col
          md={6}
          className="d-flex justify-content-center align-items-center mt-4 mb-4"
        >
          <div>
            <img
              src="giorgos.jpg"
              alt="Γιώργος Καούρης"
              className="img-fluid"
              style={{ width: "400px", height: "500px",cursor: 'pointer' }}
              onClick={() =>
                handleOpenModal({
                  name: "ΓΙΩΡΓΟΣ ΚΑΟΥΡΗΣ",
                  image: "giorgos.jpg",
                  occupation: "ΠΟΛΙΤΙΚΟΣ ΜΗΧΑΝΙΚΟΣ",
                })
              }
            />
            <h4 style={{ color: "white", marginTop: "10px" }}>
              ΓΙΩΡΓΟΣ ΚΑΟΥΡΗΣ
            </h4>
            <h4 style={{ color: "white" }}>ΠΟΛΙΤΙΚΟΣ ΜΗΧΑΝΙΚΟΣ</h4>
          </div>
        </Col>
      </Row> */}

      {/* Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{selectedPerson.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedPerson.name === "ΘΑΝΑΣΗΣ ΚΑΟΥΡΗΣ" && (
            <>
              <p>
                Γεννήθηκα και σπούδασα στην Πάτρα. Η επαγγελματική μου πορεία
                είναι στενά συνδεδεμένη με την ανάπτυξη και εξέλιξη στον τομέα
                της κατασκευής, μετρώντας περισσότερα από 40 χρόνια στο χώρο
                αυτό.
              </p>
              <p>
                Μέσα από την εμπειρία αυτή, τη συνεργασία με διάφορες ομάδες
                επαγγελματιών και την ανάληψη και διαχείριση ποικιλίας project,
                από κατοικίες έως μεγάλες βιομηχανικές εγκαταστάσεις, έχω
                ειδικευθεί στην κατασκευή και τη διαχείριση έργων και έχω
                αποκτήσει σφαιρική εμπειρία σε όλα τα στάδια της κατασκευής, από
                τον σχεδιασμό μέχρι την ανάπτυξη και την ολοκλήρωση.
              </p>
              <p>
                Συνεχώς αναζητώ νέους τρόπους εξέλιξης και καινοτομίας στα έργα
                μου, ενώ το μεγάλο μου όραμα είναι να συμβάλω στη δημιουργία
                βιώσιμων και ανθεκτικών κατασκευών που θα συμβαδίζουν με τις
                εξελισσόμενες ανάγκες της κοινωνίας και θα έχουν πράσινο
                αποτύπωμα.
              </p>
            </>
          )}

          {selectedPerson.name === "ΓΙΩΡΓΟΣ ΚΑΟΥΡΗΣ" && (
            <>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
                lobortis ligula fringilla lacinia blandit. Vestibulum rutrum
                quis lectus at eleifend. Mauris eget ultrices nunc. Nunc id
                mauris pulvinar, venenatis nisl vel, ultricies ante.
                Pellentesque faucibus est eget velit eleifend ultricies.
                Vestibulum ipsum leo, suscipit in accumsan eu, sodales imperdiet
                justo. Suspendisse urna augue, dapibus sollicitudin venenatis
                sit amet, semper non sem.
              </p>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
                lobortis ligula fringilla lacinia blandit. Vestibulum rutrum
                quis lectus at eleifend. Mauris eget ultrices nunc. Nunc id
                mauris pulvinar, venenatis nisl vel, ultricies ante.
                Pellentesque faucibus est eget velit eleifend ultricies.
              </p>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
                lobortis ligula fringilla lacinia blandit. Vestibulum rutrum
                quis lectus at eleifend. Mauris eget ultrices nunc. Nunc id
                mauris pulvinar, venenatis nisl vel, ultricies ante.
                Pellentesque faucibus est eget velit eleifend ultricies.
              </p>
            </>
          )}
        </Modal.Body>
      </Modal>
    </Container>
  );
}

export default AboutUs;
