import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import MyModal from './util/MyModal';

function Services() {
  useEffect(() => {
    document.title = 'ΥΠΗΡΕΣΙΕΣ';
  }, []);

  const [currentImage, setCurrentImage] = useState(1);
  const [containerMinHeight, setContainerMinHeight] = useState(0); // Initialize with 0

  const imageSources = [
    'image1.jpg',
    'image2.jpg',
    'image3.jpg',
    'image4.jpg',
    'image5.jpg',
  ];

  useEffect(() => {
    const preloadImages = () => {
      for (let i = 0; i < imageSources.length; i++) {
        const img = new Image();
        img.src = imageSources[i];
        img.onload = () => {
          // Calculate the maximum image height and set it as the minimum height
          const imageHeight = img.height;
          setContainerMinHeight(Math.max(containerMinHeight, imageHeight));
        };
      }
    };

    preloadImages();

    const interval = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage === 5 ? 1 : prevImage + 1));
    }, 2000); // Change image every 2 seconds

    return () => {
      clearInterval(interval);
    };
  }, [containerMinHeight]);


  const [modalState, setModalState] = useState({});

  const openModal = (serviceId) => {
    setModalState({ ...modalState, [serviceId]: true });
  };

  const closeModal = (serviceId) => {
    setModalState({ ...modalState, [serviceId]: false });
  };


  const servicesData = [
    {
      id: 1,
      title: 'ΟΙΚΟΔΟΜΙΚΕΣ ΑΔΕΙΕΣ',
      description:`H Έκδοση Οικοδομικής Άδειας είναι το στάδιο της αποτύπωσης της μελέτης του κάθε κτιρίου  στα σχέδια που 
      κατατίθενται στα αρμόδια πολεοδομικά γραφεία η Υπηρεσίες Δόμησης.  Έχοντας ασχοληθεί με πλειάδα τεχνικών έργων, διαφόρων ειδών και
       χρήσεων, και έχοντας διεκπεραιώσει αρκετές φορές τις διαδικασίες έκδοσης αδειών διαφόρων κατηγοριών και σκοπών, η εταιρία μας 
      κατέχει μεγάλη τεχνογνωσία και εμπειρία στις διαδικασίες έκδοσης αδειών. Έτσι, είναι σε θέση να αναλάβει την αδειοδότηση κάθε είδους κατασκευής, αλλά και λειτουργίας.
      `,
      imageUrl: 'adeia.jpg',
      className: 'circular-image-ad'
    },
    {
      id: 2,
      title: 'ΚΑΤΑΣΚΕΥΕΣ',
      description: `
      Η εταιρία μας δραστηριοποιείται στο χώρο της Κατασκευής - Επίβλεψης σε όλη την Ελλάδα από το 1992. 
 
Η διαρκής ενημέρωση και έρευνα αγοράς πάνω στις τεχνολογικές εξελίξεις και τα νέα δομικά υλικά, μας επιτρέπουν να έχουμε ένα άρτιο και βιοκλιματικό αποτέλεσμα για κάθε κτίριο ξεχωριστά. 
 
Μετά από 30 χρόνια κατασκευών σας παραδίδουμε την κατοικία που ονειρεύεστε με τη Συμβολή σας σε όλη διαδικασία, καθώς χέρι - χέρι θα δημιουργήσουμε αυτό το οποίο επιθυμείτε, θα αναλύσουμε τις ανάγκες σας και μέσα στα πολεοδομικά πλαίσια θα υλοποιήσουμε τον από κοινού το όνειρό σας για την στέγασή σας. 
`,
      imageUrl: 'construction.jpg',
      className: 'circular-image-large'

    },
    {
      id: 3,
      title: 'ΜΕΛΕΤΕΣ',
      description: `
      Αναλαμβάνουμε τη μελέτη νέων κτιρίων όπως μονοκατοικίες, πολυκατοικίες, βιομηχανικά κτίρια, αποθήκες κ.α. με κύριο στόχο την ικανοποίηση των αναγκών των πελατών μας, προτείνουμε ιδέες και λύσεις που προσαρμόζονται με την προσωπικότητα του κάθε πελάτη.  
Έχοντας την τεχνογνωσία για να αντιμετωπίσουμε οποιοδήποτε τεχνικό πρόβλημα προχωρούμε σε λύσεις με σκοπό να κάνουμε το χώρο σας ξεχωριστό ώστε το αποτέλεσμα να καλύπτει τις απαιτήσεις και χωρίς να υπερβαίνει και την οικονομική σας δυνατότητα.
`,
      imageUrl: 'meleti.jpg',
      className: 'circular-image'
    },
    {
      id: 4,
      title: 'ΤΑΚΤΟΠΟΙΗΣΗ ΑΥΘΑΙΡΕΤΩΝ',
      description:
        `Τακτοποίηση – Νομιμοποίηση αυθαιρέτων κατασκευών με τον Νόμο 4495/2017. 
        Η ρύθμιση αποτελεί απαραίτητη προϋπόθεση για να μπορέσετε να μεταβιβάσετε ή να πουλήσετε το ακίνητό σας. 
        Γνωρίζοντας εξαιρετικά την ισχύουσα Νομοθεσία, με σωστή ενημερωμένη για τις τελευταίες νομοθετικές εξελίξεις στον τομέα των ακινήτων και στον τομέα των αδειοδοτήσεων, η εταιρία μας αναλαμβάνει και διεκπεραιώνει όλες τις διαδικασίες για την τακτοποίηση την νομιμοποίηση των ακινήτων σας.`,
      imageUrl: 'aytaireta.jpg',
      className: 'circular-image'
    },
    {
      id: 5,
      title: 'ΣΥΝΤΑΞΗ ΜΕΛΕΤΩΝ ΓΙΑ ΠΡΟΓΡΑΜΜΑΤΑ ΕΣΠΑ & ΔΗΜΟΣΙΩΝ ΕΠΕΝΔΥΣΕΩΝ',
      description: `
      Η εταιρία μας, σε συνεργασία με ομάδα εξειδικευμένων μηχανικών, αναλαμβάνει τη σύνταξη φακέλων για χρηματοδοτούμενα προγράμματα (όπως ΕΣΠΑ). 
      Συγκεκριμένα, ανάλογα με το χρηματοδοτούμενο πρόγραμμα αναλαμβάνουμε την εκπόνηση μελετών, την έκδοση αδειών καθώς και τεχνικές συμβουλευτικές υπηρεσίες για την ολοκλήρωση του φακέλου
`,
      imageUrl: 'aytonomw.jpg',
      className: 'circular-image'
    },
    {
      id: 6,
      title: 'ΗΛ. ΤΑΥΤΟΤΗΤΑ ΚΤΙΡΙΩΝ ',
      description: `
      Από την 1η Ιανουαρίου 2021 τέθηκε σε εφαρμογή η ηλεκτρονική ταυτότητα του κτιρίου, η οποία θα αποτελέσει ουσιαστικά το Μητρώο όλου του κτιριακού αποθέματος της χώρας. 
Το ηλεκτρονικό σύστημα των ακινήτων θεσμοθετήθηκε το 2011, ωστόσο επανέρχεται με την ενεργοποίησή του από την κυβέρνηση, αλλά και τις επιβαρύνσεις που φέρνει για την πώληση και κάθε άλλη μεταβίβαση ακινήτων καθώς και στην νομιμοποίηση αυθαιρέτων, αφού πλέον είναι υποχρεωτική. 
Σκοπός της Ηλεκτρονικής Ταυτότητας Κτιρίου είναι η αποτύπωση της υφιστάμενης κατάστασης του κτιρίου ή της διηρημένης ιδιοκτησίας και των αδειών τους, καθώς και η παρακολούθηση και ο έλεγχος των μεταβολών τους κατά τη διάρκεια του χρόνου ζωής τους. 
`,
      imageUrl: 'taytotita.jpg',
      className: 'circular-image-large'
    },

    {
      id: 7,
      title: 'ΒΕΒΑΙΩΣΗ ΜΗΧΑΝΙΚΟΥ',
      description: `
      Στην εταιρία μας σας εγγυόμαστε την υπεύθυνη και έγκυρη νομιμοποίηση του ακινήτου σας, με βεβαίωση μηχανικού.
       Η εταιρία μας έχει διεκπεραιώσει εκατοντάδες υποθέσεις νομιμοποίησης ακινήτων σε όλη την Ελλάδα, πάντα σωστά, γρήγορα και χωρίς ταλαιπωρία για τον πελάτη. Με την ολοκλήρωση των παραπάνω διαδικασιών 
      θα παραλάβετε όλα τα απαραίτητα έγγραφα σε πολύ σύντομο χρονικό διάστημα, οικονομικά και έγκυρα χωρίς κανένα μετέπειτα πρόβλημα.`,
      imageUrl: 'vevaiwsi.jpg',
      className: 'circular-image-large'

    },
    {
      id: 8,
      title: 'ΕΝΕΡΓΕΙΑΚΕΣ ΜΕΛΕΤΕΣ / ΕΝΕΡΓΕΙΑΚΑ ΠΙΣΤΟΠΟΙΗΤΙΚΑ ',
      description: `
      Το ενεργειακό πιστοποιητικό αφορά όλα τα κτήρια και τις αυτοτελής ιδιοκτησίες, αφού για όλες τις νέες μισθώσεις κτιρίων, αγοραπωλησίες κτιρίων, νέα ή ριζικά ανακαινιζόμενα κτίρια αλλά ακόμα και για την ένταξη κτιρίων κατοικίας στο 
πρόγραμμα "Εξοικονομώ - Αυτονομώ", απαιτείται η έκδοση Πιστοποιητικού Ενεργειακής Απόδοσης. 
 
Το ενεργειακό πιστοποιητικό είναι πλέον υποχρεωτικό και για ακίνητα κάτω των 50 τ.μ και εκδίδεται από Ενεργειακό Επιθεωρητή (μηχανικό ο οποίος έχει ενταχθεί σε ειδικό Μητρώο), και στο οποίο αποτυπώνεται η ενεργειακή απόδοση του κτιρίου.  
 
Με το πιστοποιητικό το κάθε κτίριο κατατάσσεται σε ενεργειακή κατηγορία (υπάρχουν εννέα κατηγορίες από Α+ έως Η), ενώ ο επιθεωρητής καταγράφει και συστάσεις για τη βελτίωση της Ενεργειακής Απόδοσης του Κτιρίου. 
`,
      imageUrl: 'pistopoihtika.jpg',
      className: 'circular-image'

    },
    {
      id: 9,
      title: 'ΑΝΑΚΑΙΝΙΣΕΙΣ',
      description: `
      Η ανακαίνιση σπιτιού είναι απαραίτητη για όλους τους ιδιοκτήτες ακινήτων που θέλουν ένα πιο λειτουργικό και ενεργειακά οικονομικό σπίτι. Σπίτια που έχουν υποστεί βλάβες ή φθορές από το χρόνο μπορούν να πάρουν ξανά αξία με μία αποτελεσματική και ολική ανακαίνιση σπιτιού.  

      Ανακαίνιση σπιτιού θεωρείται όποια ενέργεια γίνει προκειμένου να βελτιωθεί η όψη, η αισθητική, η λειτουργικότητα, η ασφάλεια ή η ενεργειακή απόδοση μιας κατοικίας. Το εύρος των ενεργειών που πρέπει να ακολουθηθούν ποικίλουν αναλόγως τις ανάγκες της εκάστοτε κατοικίας και με το αν ο ιδιοκτήτης θέλει να προχωρήσει σε ολική ή μερική ανακαίνιση βάσει των αναγκών του.  
      
      Ανάλογα με την πολυπλοκότητα του έργου, οι ανακαινίσεις απαιτούν τον συντονισμό διαφόρων ειδικοτήτων. Η εταιρία μας υλοποιεί κάθε έργο με τον συντονισμό όποιων ειδικοτήτων απαιτούνται (πχ. αρχιτέκτονες, μηχανικοί, ηλεκτρολόγοι, υδραυλικοί και ξυλουργοί κ.α. συνεργεία). 
      
      Εκτός από τις εργασίες που απαιτούνται για το ίδιο το έργο, οι ανακαινίσεις συχνά απαιτούν επίσης άδειες και επιθεωρήσεις. Η εταιρία μας αναλαμβάνει τον προγραμματισμό και την εκπόνηση όλων των απαιτούμενων διαδικασιών. Όταν γίνονται σωστά, οι ανακαινίσεις μπορούν να προσθέσουν αξία σε ένα ακίνητο, να αυξήσουν τη λειτουργικότητα και τη βιωσιμότητά του και να το καταστήσουν πιο ελκυστικό για τους ενοίκους του, τους πιθανούς αγοραστές. Στην περίπτωση των επαγγελματικών χώρων, προσδίδουν ανά περίπτωση το ύφος που απαιτείται για το προφιλ της επιχείρησης. 
      
       `,
      imageUrl: 'anakainiseis.jpg',
      className: 'circular-image-large'

    },
    {
      id: 10,
      title: 'ΕΠΙΣΚΕΥΗ & ΑΝΑΠΑΛΑΙΩΣΗ ΔΙΑΤΗΡΗΤΕΩΝ ',
      description: `
      Η ανακαίνιση διατηρητέου αποτελεί μια απαιτητική διαδικασία και παράλληλα μια πρόκληση, λόγω των επιπλοκών που εμφανίζονται από τους 
      εκάστοτε  περιορισμούς. Η εταιρία μας διαθέτει εμπειρία στην αναπαλαίωση και ανακατασκευή διατηρητέων οικημάτων σε όλη την Ελλάδα, ενώ τα 
      στελέχη της διακρίνονται για την υψηλή τους τεχνογνωσία και για τον άκρατο επαγγελματισμό. Έτσι η εταιρία βρίσκεται στην πλεονεκτική θέση να μπορεί παρέχει ιδανικές λύσεις για κάθε είδους ανακαίνιση. 
       `,
      imageUrl: 'diathrhtea.jpg',
      className: 'circular-image-large'

    },
    {
      id: 11,
      title: 'ΑΠΟΤΥΠΩΣΕΙΣ & ΤΟΠΟΓΡΑΦΙΚΑ  ',
      description: `
      Τα τοπογραφικά διαγράμματα με συντεταγμένες ΕΓΣΑ ’87 είναι απαραίτητα για διάφορες πράξεις όπως αγοραπωλησίες, σύσταση ιδιοκτησιών, οικοδομικές άδειες, αποσπάσματα κτηματολογίου κλπ. 
       `,
      imageUrl: 'topografiko.jpg',
      className: 'circular-image-large'

    },
    {
      id: 12,
      title: 'ΕΣΩΤΕΡΙΚΗ ΔΙΑΚΟΣΜΗΣΗ ',
      description: `
      Αναλαμβάνουμε τη μελέτη διακόσμησης εσωτερικών χώρων, 
      τη διαρρύθμιση των χώρων, την επιλογή των υλικών ,των επίπλων και 
      του εξοπλισμού σε κατοικίες, επαγγελματικούς χώρους και σε τουριστικά καταλύματα. Δίνουμε χαρακτήρα στο χώρο σας, εστιάζουμε στις ανάγκες σας και σας προτείνουμε νέο και λειτουργικό σχεδιασμό, μέσα από ευρεία γκάμα υλικών. Οι αρμονικές γραμμές, ο ιδανικός φωτισμός, οι καλαίσθητοι χρωματικοί συνδυασμοί, τα καινοτόμα υλικά και η εξειδικευμένη εργονομική διαρρύθμιση του κάθε δωματίου μεταμορφώνουν το σπίτι ή τον επαγγελματικό σας χώρο σε ένα έξυπνο και πρωτοποριακό περιβάλλον υψηλής αισθητικής
       που θα ικανοποιεί τις ανάγκες σας σε παρόν και μέλλον.        `,
      imageUrl: 'aytaireta.jpg',
      className: 'circular-image-large'

    },
   
  ];
  return (
    <div style={{ marginBottom: '50px', marginTop: '50px' }}>
      <div className="row ">
        <div className="col-md-12">
          <div className='container'>

            <h2 className="text-center mb-4 theme-color">Υπηρεσίες Μηχανικού – Τεχνική Εταιρία</h2>
            <p style={{}}>
              Η <strong> ΤΕΧΝΙΚΗ ΕΤΑΙΡΙΑ ΥΙΟΙ Κ. ΚΑΟΥΡΗ O.E. </strong> καλύπτει όλο το εύρος των υπηρεσιών που αφορούν το προγραμματισμό, τη μελέτη και τη κατασκευή ή ανακαίνιση ενός έργου.
            </p>
            <p>
              Οι υψηλές απαιτήσεις μας στην αισθητική και στη λειτουργικότητα, σε συνδυασμό με οικονομικά αποδεκτές λύσεις, διαμορφώνουν το ξεχωριστό προφίλ της εταιρίας μας.
              <br />
              Η εταιρία μας, δραστηριοποιείται στο χώρο της μελέτης κατασκευής και έκδοσης αδειών από το 1992,
              με μια μεγάλη γκάμα υπηρεσιών. Παρέχει με γνώμονα την καλύτερη εξυπηρέτηση των πελατών της, αλλά και σύμφωνα πάντα με τις ανάγκες και τις απαιτήσεις αυτών, μεγάλη γκάμα υπηρεσιών.
              <br />
            </p>
            <p>
              Διαθέτοντας ένα εξαιρετικά εξειδικευμένο δίκτυο συνεργατών, έχει τη δυνατότητα να καλύψει όλες τις απαιτούμενες συμβουλευτικές υπηρεσίες εξασφαλίζοντας έτσι την επιτυχή διεκπεραίωση έργων.
            </p>
          </div>
        </div>
      </div>
      <div className="row">
        <div
          style={{
            display: 'flex',
            justifyContent: 'center', // Horizontally center content
            textAlign: 'center',
              height: '90%',
              minHeight: '35vw' 
          }}
        >

          {currentImage === 1 && (
            <img
            className="d-block w-95"
              id="image1"
              src="image1.jpg"
              alt="Image 1"
              style={{ width: '80%', height: '70%' }}

            />
          )}
          {currentImage === 2 && (
            <img
            className="d-block w-95"
              id="image2"
              src="image2.jpg"
              alt="Image 2"
              style={{ width: '80%', height: '70%' }}
            />
          )}
          {currentImage === 3 && (
            <img
            className="d-block w-95"
              id="image3"
              src="image3.jpg"
              alt="Image 3"
              style={{ width: '80%', height: '70%' }}
            />
          )}
          {currentImage === 4 && (
            <img
            className="d-block w-95 h-80"
              id="image4"
              src="image4.jpg"
              alt="Image 4"
              style={{ width: '80%', height: '70%' }}
            />
          )}
          {currentImage === 5 && (
            <img
            className="d-block w-95"
              id="image5"
              src="image5.jpg"
              alt="Image 5"
              style={{ width: '80%', height: '70%' }}
            />
          )}

          {/* <img
            className="d-block w-95"
            src={"servicesPic.jpg"}
            alt="services"
          /> */}
        </div>
      </div>
      <h2 className="text-center mt-5 mb-4  theme-color"> ΥΠΗΡΕΣΙΕΣ </h2>
      <div className="row service-box">
        {servicesData.map((service) => (
          <>
            <div key={service.id} className="col-md-2">
              <div className="text-center service">
                <img src={service.imageUrl} alt={service.title} className={service.className} />
                <div style={{ height: 50, alignItems: 'center' , display:'flex', justifyContent: 'center' }}>
                  <h5 style={{ fontSize: 17 }}className='theme-color'>{service.title}</h5>
                </div>
                <div className="description-container">
                  <div style={{ padding: 20 }}>
                    <p className="service-description">{service.description}</p>
                  </div>
                </div>
                <Button
                  onClick={() => openModal(service.id)} // Pass the service.id to open the corresponding modal
                  className='theme-color' style={{ background: 'none', border: 'none', padding: '0', textDecoration: 'underline', cursor: 'pointer', marginBottom: 30 }}>Περισσότερα</Button>
              </div>
            </div>
            <MyModal
              title={service.title}
              content={service.description}
              showModal={modalState[service.id]}
              closeModal={() => closeModal(service.id)}
            />          </>
        ))}
      </div>
      {/* <Container style={{ margin: 0, padding: 0 }}>
        <div className='' style={{  paddingTop: 30 ,position: 'relative', width: '100vw', textAlign: 'center' }}>
          <h3 className="theme-color"> ΕΧΕΤΕ ΚΑΠΟΙΟ</h3>
          <h2 className="theme-color"> ΚΑΤΑΣΚΕΥΑΣΤΙΚΟ ΕΡΓΟ</h2>
          <h3 className="theme-color"> ΓΙΑ ΥΛΟΠΟΙΗΣΗ;</h3>
            <Button
             as={Link}
             to="/contact"
              variant="primary"
              style={{
                // Adjust the button's width
                height: '40px', // Adjust the button's height
                zIndex: 1, // Ensure the button appears above the image
                marginTop: '20px',
                marginBottom: '20px',
                border: 'none',
                backgroundColor: '#0c3348'
              }}
            >
              ΖΗΤΗΣΤΕ ΠΡΟΣΦΟΡΑ
            </Button>
          </div>
       
      </Container> */}
    </div>
  );
}

export default Services;
