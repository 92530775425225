import React from 'react';
import { Container } from 'react-bootstrap';

function Footer() {
  return (
    <footer  className="" style={{ marginTop: '0px!important',  minHeight: 90, backgroundColor: '#1f334b'}} >
      <Container className='justify-content-center mt-3'>
        <p className="text-center" style={{color: 'white'}}>
          Copyright © 2023 Tεχνική Εταιρία Υιοί Κων. ΚΑΟΥΡΗ O.E.
        </p>
      </Container>
    </footer>
  );
}

export default Footer;
