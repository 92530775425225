import React from 'react';
import { Modal, Button } from 'react-bootstrap';

function MyModal({ title, content, showModal, closeModal }) {
  return (
    <Modal show={showModal} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {content}
      </Modal.Body>
      <Modal.Footer>
        <Button style = {{ backgroundColor: '#0c3348' ,  border: 'none', }} onClick={closeModal}>
          ΚΛΕΙΣΙΜΟ
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default MyModal;
