import React from 'react';
import { Carousel } from 'react-bootstrap';

function CarouselItem({ data }) {
    return (
      <Carousel style={{ width: '100%' , height: "60%"}}>
        {data &&
          data.map((item,index) => (
            <Carousel.Item key={`${item.id}-${index}`} interval={2000 } >
              <img
                className="d-block w-100"
                src={item.imageSrc}
                alt="Kaouris Logo"
              />
              <Carousel.Caption className="d-none d-md-block" style={{ width: '44%', margin: '0 auto' }}>
                <h3 style={{  textShadow: "6px 6px 12px  rgba(0,0,0,0.8)", }}>{item.caption}</h3>
              </Carousel.Caption>
            </Carousel.Item>
          ))}
      </Carousel>
    );
  }  

export default CarouselItem ;