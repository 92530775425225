// projectsData.js

const projectsData = {
    singleFamilyHomes: [
        {
            id: 130,
            title: "Μονοκατοικία επί των οδών Άνδρου & Κουφονησίων στον Καστελλόκαμπο Πατρών",
            description: "Νέα διώροφη κατοικία με σοφιτα και υπόγειο.",
            imageUrl: "2024/04/monokatoikia/3D2.jpg",
            carouselData: [
                { imageSrc: '2024/04/monokatoikia/3D1.jpg', caption: '' },
                { imageSrc: '2024/04/monokatoikia/3D2.jpg', caption: '' },
                { imageSrc: '2024/04/monokatoikia/3D3.jpg', caption: '' },
            ],
        },
        {
            id: 67,
            title: "Διώροφη κατοικία με υπόγειο & σοφίτα, Μποζαΐτικα",
            description: "Διώροφη κατοικία με υπόγειο & σοφίτα, Μποζαΐτικα.",
            imageUrl: "2023/09/mpozaitika/1.jpg",
            carouselData: [
                { imageSrc: '2023/09/mpozaitika/1.jpg', caption: '' },
                { imageSrc: '2023/09/mpozaitika/2.jpg', caption: '' },
                { imageSrc: '2023/09/mpozaitika/4.jpg', caption: '' },
                { imageSrc: '2023/09/mpozaitika/5.jpg', caption: '' },

            ],
        },
        {
            id: 68,
            title: "Διώροφη κατοικία, Ακράτα",
            description: "Διώροφη κατοικία, Ακράτα.",
            imageUrl: "underconstruction.jpg",
            carouselData: [
                { imageSrc: 'underconstruction.jpg', caption: '' },

            ],
        },
        {
            id: 40,
            title: "  Διώροφη κατοικία & Ισόγεια Αποθήκη, Άγιος Βασίλειος , Ρίο ",
            description: "Κατοικία με υπόγειο  και Ισόγεια Αποθήκη στο Άγιο Βασίλειο, Ρίο.",
            imageUrl: "2023/09/agiosvasilis/20220602_081845.jpg",
            carouselData: [
                { imageSrc: '2023/09/agiosvasilis/20220602_081845.jpg', caption: '' },
                { imageSrc: '2023/09/agiosvasilis/20220602_081949.jpg', caption: '' },
                { imageSrc: '2023/09/agiosvasilis/20220602_082005.jpg', caption: '' },
                { imageSrc: '2023/09/agiosvasilis/20220701_080829.jpg', caption: '' },
                { imageSrc: '2023/09/agiosvasilis/20230618_113629.jpg', caption: '' },

            ],
        },
        {
            id: 39,
            title: " Διώροφη κατοικία με υπόγειο, Π.Π.Γερμανού Ρίο Πάτρα",
            description: "Διώροφη κατοικία με υπόγειο & σοφίτα στη Π.Π.Γερμανού Ρίο Πάτρα.",
            imageUrl: "2023/09/20201119_141901.jpg",
            carouselData: [
                { imageSrc: '2023/09/20201119_141901.jpg', caption: '' },
                { imageSrc: '2023/09/20201119_141916.jpg', caption: '' },
                { imageSrc: '2023/09/20201119_143214.jpg', caption: '' },
            ],
        },
        {
            id: 42,
            title: " Διώροφη κατοικία με υπόγειο, Τσελείκα Πλατανίου, Ρίο ",
            description: "Κατοικία με υπόγειο , στον Τσελείκα Πλατανίου, Ρίο. ",
            imageUrl: "2023/09/tseleika/20190607_134744.jpg",
            carouselData: [
                { imageSrc: '2023/09/tseleika/20190607_134744.jpg', caption: '' },
                { imageSrc: '2023/09/tseleika/20190607_134758.jpg', caption: '' },
                { imageSrc: '2023/09/tseleika/20190607_134803.jpg', caption: '' },
                { imageSrc: '2023/09/tseleika/20190607_134821.jpg', caption: '' },
                { imageSrc: '2023/09/tseleika/20190607_134834.jpg', caption: '' },
            ],
        },
        {
            id: 43,
            title: "  Διώροφη οικοδομή με υπόγειο, Ναύπακτος  ",
            description: "Διώροφη οικοδομή με υπόγειο στην Ναύπακτο.",
            imageUrl: "2023/09/naypatkos/20190204_090638.jpg",
            carouselData: [
                { imageSrc: '2023/09/naypatkos/20190204_090638.jpg', caption: '' },
                { imageSrc: '2023/09/naypatkos/20190204_090647.jpg', caption: '' },
                { imageSrc: '2023/09/naypatkos/20190204_090720.jpg', caption: '' },
                { imageSrc: '2023/09/naypatkos/20190204_090842.jpg', caption: '' },
                { imageSrc: '2023/09/naypatkos/20190204_090921.jpg', caption: '' },
            ]
        },

        // {
        //     id: 44,
        //     title: "  Διώροφη κατοικία, Μεγαλοχώρι Θήρας ",
        //     description: "Διώροφη κατοικία με υπόγειο στο Μεγαλοχώρι Θήρας ",
        //     imageUrl: "2023/09/thyra/014.jpg",
        //     carouselData: [
        //         { imageSrc: '2023/09/thyra/014.jpg', caption: '' },
        //         { imageSrc: '2023/09/thyra/015.jpg', caption: '' },
        //         { imageSrc: '2023/09/thyra/024.jpg', caption: '' },
        //         { imageSrc: '2023/09/thyra/IMG-9a15c415fd07a33f54af76dbe491b7f1-V.jpg', caption: '' },
        //         { imageSrc: '2023/09/thyra/IMG-19a81625c3d210c944779404fe19950c-V.jpg', caption: '' },

        //     ],
        // },
        {
            id: 45,
            title: "Iσόγεια κατοικία με κολυμβητική δεξαμενή (Παραλία Προαστείου)",
            description: "Iσόγεια κατοικία με κολυμβητική δεξαμενή στη Παραλία Προαστείου. ",
            imageUrl: "2023/09/paralia/20170613_130229.jpg",
            carouselData: [
                { imageSrc: '2023/09/paralia/20170613_130229.jpg', caption: '' },
                { imageSrc: '2023/09/paralia/20170622_135552.jpg', caption: '' },
                { imageSrc: '2023/09/paralia/20170622_135554.jpg', caption: '' },
                { imageSrc: '2023/09/paralia/20170622_135731.jpg', caption: '' },
                { imageSrc: '2023/09/paralia/20170622_135852.jpg', caption: '' },
                { imageSrc: '2023/09/paralia/20170622_135901.jpg', caption: '' },
                { imageSrc: '2023/09/paralia/20170622_135907.jpg', caption: '' },
                { imageSrc: '2023/09/paralia/20170622_135917.jpg', caption: '' },
                { imageSrc: '2023/09/paralia/20170622_140003.jpg', caption: '' },

            ],
        },
        {
            id: 3,
            title: "Πέτρινη μονοκατοικία στην ορεινή Ναυπακτία",
            description: 'Με σεβασμό στο παραδοσιακό οικισμό, πέτρινη μονοκατοικία στην ορεινή Ναυπακτία.',
            imageUrl: "2012/11/DSC01825-1024x768.jpg",
            carouselData: [
                { imageSrc: '2012/11/DSC01825-1024x768.jpg', caption: '' },
                { imageSrc: '2012/11/DSC01829-1024x768.jpg', caption: '' },
                { imageSrc: '2012/11/DSC01823-1024x768.jpg', caption: '' },
                { imageSrc: '2012/11/DSC01821-768x1024.jpg', caption: '' },
                { imageSrc: '2012/11/DSC01816-1024x768.jpg', caption: '' },
            ]
        },
        {
            id: 4,
            title: "Συγκρότημα 9 ανεξάρτητων κατοικιών, Προφήτης Ηλίας, Πάτρα",
            description: 'Ένας σύγχρονος μοντέρνος "μικρός οικισμός" σε πλαγιά μέσα σε καταπράσινο περιβάλλον κοντά στο κέντρο της Πάτρας.',
            imageUrl: "2012/11/katoikies22-640x441.jpg",
            carouselData: [
                { imageSrc: '2012/11/katoikies22-640x441.jpg', caption: '' },
                { imageSrc: '2012/11/Untitled-12-1024x712.jpg', caption: '' },
                { imageSrc: '2012/11/Untitled-7-1024x712.jpg', caption: '' },
                { imageSrc: '2012/11/Untitled-5-1024x712.jpg', caption: '' },
                { imageSrc: '2012/11/Untitled-11-1024x712.jpg', caption: '' },
                { imageSrc: '2012/11/photo5678-300x206.jpg', caption: '' },
                { imageSrc: '2012/11/photo90-210x300.jpg', caption: '' },
                { imageSrc: '2012/11/photo9-300x204.jpg', caption: '' },
            ]
        },
        {

            id: 6,
            title: "Μονοκατοικία στην Καρυά Πατρών",
            description: 'Μονοκατοικία κατασκευασμένη σε έντονη κλίση με θέα τον Πατραικό κόλπο.',
            imageUrl: "2012/11/IMGP0870-1024x768.jpg",
            carouselData: [
                { imageSrc: '2012/11/IMGP0870-1024x768.jpg', caption: '' },
                { imageSrc: '2012/11/IMGP0841-1024x768.jpg', caption: '' },
                { imageSrc: '2012/11/IMGP0848-1024x768.jpg', caption: '' },
                { imageSrc: '2012/11/IMGP0850-1024x768.jpg', caption: '' },
                { imageSrc: '2012/11/IMGP0851-1024x768.jpg', caption: '' },
                { imageSrc: '2012/11/IMGP0853-1024x768.jpg', caption: '' },
                { imageSrc: '2012/11/IMGP0854-1024x768.jpg', caption: '' },
                { imageSrc: '2012/11/IMGP0862-1024x768.jpg', caption: '' },
                { imageSrc: '2012/11/IMGP0865-1024x768.jpg', caption: '' },

            ]
        },

        {
            id: 43,
            title: "  Διώροφη οικοδομή με υπόγειο, Ναύπακτος  ",
            description: "Διώροφη οικοδομή με υπόγειο στην Ναύπακτο. ",
            imageUrl: "2023/09/naypatkos/20190204_090638.jpg",
            carouselData: [
                { imageSrc: '2023/09/naypatkos/20190204_090638.jpg', caption: '' },
                { imageSrc: '2023/09/naypatkos/20190204_090647.jpg', caption: '' },
                { imageSrc: '2023/09/naypatkos/20190204_090720.jpg', caption: '' },
                { imageSrc: '2023/09/naypatkos/20190204_090842.jpg', caption: '' },
                { imageSrc: '2023/09/naypatkos/20190204_090921.jpg', caption: '' },

            ],
        },
        {
            id: 87,
            title: "Συγκρότημα 7 κατοικιών  Περίβολος Σαντορίνης ",
            description: "Συγκρότημα 7 κατοικιών με υπόγεια και σοφίτες Περίβολος Σαντορίνης.",
            imageUrl: "2024/04/santorini/front_cropped_photo.bmp",
            carouselData: [
                { imageSrc: '2024/04/santorini/photo1.bmp', caption: '' },
                { imageSrc: '2024/04/santorini/photo2.bmp', caption: '' },
                { imageSrc: '2024/04/santorini/photo3.bmp', caption: '' },
                { imageSrc: '2024/04/santorini/photo4.bmp', caption: '' },

               
                // { imageSrc: '2023/09/perivolos/photo1.bmp', caption: '' },
                // { imageSrc: '2023/09/perivolos/photo2.bmp', caption: '' },
                // { imageSrc: '2023/09/perivolos/photo3.bmp', caption: '' },
                // { imageSrc: '2023/09/perivolos/photo4.bmp', caption: '' },
                // { imageSrc: '2023/09/perivolos/photo5.bmp', caption: '' },
                // { imageSrc: '2023/09/perivolos/photo6.bmp', caption: '' },

            ],
        },      
       
        {
            id: 1,
            title: "Μονοκατοικία στο Ακταίο Πατρών",
            description: "Κατοικία σε 2 επίπεδα στο Ρίο Πατρών.",
            imageUrl: "aktaio2.jpg",
            carouselData: [
                { imageSrc: 'aktaio2.jpg', caption: '' },
                { imageSrc: '/2012/11/aktaio1.jpg', caption: '' },
            ],
        },
        {
            id: 39,
            title: "Διώροφη κατοικία με υπόγειο  στο Ρίο.",
            description: "Κατοικία με υπόγειο  στο Ρίο.",
            imageUrl: "2023/09/20201119_141901.jpg",
            carouselData: [
                { imageSrc: '2023/09/20201119_141901.jpg', caption: '' },
                { imageSrc: '2023/09/20201119_141916.jpg', caption: '' },
                { imageSrc: '2023/09/20201119_143214.jpg', caption: '' },
            ],
        },
        {
            id: 2,
            title: "Συγκρότημα 3 ανεξάρτητων μονοκατοικιών στον Άγιο Βασίλειο Ρίο",
            description: 'Το συγκρότημα βρίσκεται στην περιοχή Αγίου Βασιλείου στο Ρίο Πατρών.',
            imageUrl: "2012/11/IMGP0821-1024x768.jpg",
            carouselData: [
                { imageSrc: '2012/11/IMGP0821-1024x768.jpg', caption: '' },
                { imageSrc: '2012/11/IMGP0807-1024x768.jpg', caption: '' },
                { imageSrc: '2012/11/IMGP0810-1024x768.jpg', caption: '' },
                { imageSrc: '2012/11/IMGP0812-1024x768.jpg', caption: '' },
                { imageSrc: '2012/11/IMGP0814-1024x768.jpg', caption: '' },
                { imageSrc: '2012/11/IMGP0818-1024x768.jpg', caption: '' },
                { imageSrc: '2012/11/IMGP0825-1024x768.jpg', caption: '' },
                { imageSrc: '2012/11/IMGP0836-1024x768.jpg', caption: '' },
                { imageSrc: '2012/11/IMGP0839-1024x768.jpg', caption: '' },
                { imageSrc: '2012/11/IMGP0839-1024x768.jpg', caption: '' }

            ]
        },
       
       
        {

            id: 5,
            title: "Συγκρότημα 3 ανεξάρτητων μονοκατοικιών στην Κρήνη Πατρών",
            description: 'Μονοκατοικίες στους πρόποδες του Οπλού με θέα όλη την Πάτρα και το Πατραικό κόλπο.',
            imageUrl: "2012/11/100_0542-1024x768.jpg",
            carouselData: [
                { imageSrc: '2012/11/100_0542-1024x768.jpg', caption: '' },
                { imageSrc: '2012/11/100_0522-1024x768.jpg', caption: '' },
                { imageSrc: '2012/11/100_0530-1024x768.jpg', caption: '' },
                { imageSrc: '2012/11/100_0531-1024x768.jpg', caption: '' },
                { imageSrc: '2012/11/100_0544-1024x768.jpg', caption: '' },
                { imageSrc: '2012/11/100_0546-1024x768.jpg', caption: '' },
                { imageSrc: '2012/11/100_0559-1024x768.jpg', caption: '' },
                { imageSrc: '2012/11/DSC04705-1024x768.jpg', caption: '' },
            ]
        },
     
        {
            id: 7,
            title: "Συγκρότημα 2 ανεξάρτητων μονοκατοικιών στην Κρήνη Πατρών",
            description: 'Δύο ανεξάρτητες κατοικίες στο λόφο της Κρήνης Μονοδενδρίου.',
            imageUrl: "2012/11/DSC03794-1024x768.jpg",
            carouselData: [
                { imageSrc: '2012/11/DSC03794-1024x768.jpg', caption: '' },
                { imageSrc: '2012/11/DSC03781-1024x768.jpg', caption: '' },
                { imageSrc: '2012/11/DSC03783-1024x768.jpg', caption: '' },
                { imageSrc: '2012/11/DSC03788-1024x768.jpg', caption: '' },
            ]
        },
        {
            id: 8,
            title: "Μονοκατοικία στην Άνω Καλλιθέα Πάτρας",
            description: 'Στην κορυφή της Άνω Καλλιθέας μονοκατοικία με απεριόριστη θέα.',
            imageUrl: "2012/11/DSC03971-1024x769.jpg",
            carouselData: [
                { imageSrc: '2012/11/DSC03971-1024x769.jpg', caption: '' },
                { imageSrc: '2012/11/DSC03952-769x1024.jpg', caption: '' },
                { imageSrc: '2012/11/DSC03955-769x1024.jpg', caption: '' },
                { imageSrc: '2012/11/DSC03956-769x1024.jpg', caption: '' },
                { imageSrc: '2012/11/DSC03957-769x1024.jpg', caption: '' },
                { imageSrc: '2012/11/DSC03967-769x1024.jpg', caption: '' },
                { imageSrc: '2012/11/DSC03969-769x1024.jpg', caption: '' },
                { imageSrc: '2012/11/DSC03972-1024x769.jpg', caption: '' },
                { imageSrc: '2012/11/DSC03973-769x1024.jpg', caption: '' },
                { imageSrc: '2012/11/DSC03974-769x1024.jpg', caption: '' },
                { imageSrc: '2012/11/DSC03976-769x1024.jpg', caption: '' }
            ]
        },
        {
            id: 9,
            title: "Συγκρότημα 2 ανεξάρτητων μονοκατοικιών στην Κρήνη Πατρών",
            description: 'Στην κορυφή της Άνω Καλλιθέας μονοκατοικία με απεριόριστη θέα.',
            imageUrl: "2012/11/DSC000151-1024x769.jpg",
            carouselData: [
                { imageSrc: '2012/11/DSC000151-1024x769.jpg', caption: '' },
                { imageSrc: '2012/11/DSC00011-1024x769.jpg', caption: '' },
                { imageSrc: '2012/11/DSC00015-1024x769.jpg', caption: '' }
            ]
        },
        {
            id: 10,
            title: "Μονοκατοικία στα Βραχναίικα Πατρών",
            description: 'Κατασκευή μιας πανέμορφης μονοκατοικίας με πλούσιους εξωτερικούς χώρους.',
            imageUrl: "2012/11/4-294x221.jpg",
            carouselData: [
                { imageSrc: '2012/11/4-294x221.jpg', caption: '' },
                { imageSrc: '2012/11/11-150x150.jpg', caption: '' },
                { imageSrc: '2012/11/2-150x150.jpg', caption: '' },
                { imageSrc: '2012/11/3-150x150.jpg', caption: '' },
                { imageSrc: '2012/11/5-150x150.jpg', caption: '' }

            ]
        },
        {
            id: 11,
            title: "Διπλοκατοικία στα Βραχναϊίκα Πατρών",
            description: ' Διόροφο δείγμα τυπικής διπλοκατοικίας στα Βραχνέικα.',
            imageUrl: "2012/11/DSC01782-1024x768.jpg",
            carouselData: [
                { imageSrc: '2012/11/DSC01782-1024x768.jpg', caption: '' },
                { imageSrc: '2012/11/DSC01776-1024x768.jpg', caption: '' },
                { imageSrc: '2012/11/DSC01778-768x1024.jpg', caption: '' }


            ]
        },
        {
            id: 12,
            title: "Μονοκατοικία στο Ρίο Πατρών",
            description: 'Κατασκευή μονοκατοικίας στο Ρίο Πάτρών.',
            imageUrl: "2012/11/rio-300x187.jpg",
            carouselData: [
                { imageSrc: '2012/11/rio-300x187.jpg', caption: '' },
                { imageSrc: '2012/11/rio1-300x187.jpg', caption: '' },
                { imageSrc: '2012/11/rio2-300x187.jpg', caption: '' }


            ]
        },
        {
            id: 13,
            title: "Μονοκατοικία στον Καστελλόκαμπο Πάτρας",
            description: 'Κατασκευή μονοκατοικίας στον Καστελόκαμπο Πάτρών.',
            imageUrl: "2012/11/rio3-300x187.jpg",
            carouselData: [
                { imageSrc: '2012/11/rio3-300x187.jpg', caption: '' },
                { imageSrc: '2012/11/rio4-300x187.jpg', caption: '' },
                { imageSrc: '2012/11/rio21-300x187.jpg', caption: '' }


            ]
        },
        {
            id: 14,
            title: "Διπλοκατοικία στην Εγλυκάδα Πάτρα",
            description: 'Διόροφο δείγμα τυπικής διπλοκατοικίας στην Εγλυκάδα.',
            imageUrl: "2012/11/2eglykada1-300x187.jpg",
            carouselData: [
                { imageSrc: '2012/11/2eglykada1-300x187.jpg', caption: '' },
                { imageSrc: '2012/11/2eglykada11-300x187.jpg', caption: '' },
                { imageSrc: '2012/11/2eglykada2-300x187.jpg', caption: '' }


            ]
        },
        {
            id: 15,
            title: "Μονοκατοικία στο Ρίο Πατρών",
            description: 'Κατασκευή μονοκατοικίας στο Ρίο Πάτρών.',
            imageUrl: "2012/11/monorio-1024x654.jpg",
            carouselData: [
                { imageSrc: '2012/11/monorio-1024x654.jpg', caption: '' },
                { imageSrc: '2012/11/monorio1-1024x654.jpg', caption: '' },
                { imageSrc: '2012/11/monorio2-1024x666.jpg', caption: '' }


            ]
        },
        {
            id: 16,
            title: "Μονοκατοικία στο Ρίο Πάτρας",
            description: 'Κατασκευή μονοκατοικίας στο Ρίο Πατρών.',
            imageUrl: "2012/11/rio5-300x187.jpg",
            carouselData: [
                { imageSrc: '2012/11/rio5-300x187.jpg', caption: '' }

            ]
        },
        // Ad
        // Add more single-family home projects here




    ],
    apartmentBuildings: [
        {
            id: 146,
            title: "Νέα εξαώροφη πολυκατοικία με πυλωτή, υπόγειο και δώμα στη Βύρωνος 31-33, Πάτρα",
            description: "Νέα εξαώροφη πολυκατοικία με πυλωτή, υπόγειο και δώμα στην οδό Βύρωνος 31-33 στην Πάτρα.",
            imageUrl: "2024/04/polykatoikia/3D_1.jpg",
            carouselData: [
                { imageSrc: '2024/04/polykatoikia/3D_1.jpg', caption: '' },
                { imageSrc: '2024/04/polykatoikia/3D_2.jpg', caption: '' },

            // imageUrl: "2024/04/polykatoikia/3D_1.jpg",
            // carouselData: [
            //     { imageSrc: 'underconstruction.jpg', caption: '' },
            //     { imageSrc: '2024/04/polykatoikia/3D_1.jpg', caption: '' },
            //     { imageSrc: '2024/04/polykatoikia/3D_2.jpg', caption: '' },
            //     { imageSrc: '2024/04/polykatoikia/3D_3.jpg', caption: '' },
            //     { imageSrc: '2024/04/polykatoikia/3D_4.jpg', caption: '' },


            ],
        },        
        {
            id: 17,
            title: "Πολυκατοικία στην περιοχή «Εθνικό Στάδιο» Πάτρας",
            description: `Πολυκατοικία με πυλωτή με πανταχόθεν ελεύθερο σύστημα μέσα σε καταπράσινο περιβάλλον.
            Η πολυκατοικία βρίσκεται επι των οδών Εγγονοπούλου & Χατζημιχαήλ στην περιοχή «Εθνικό Στάδιο», στην Πάτρα.` ,
            imageUrl: "2012/11/DSC02538-1024x768.jpg",
            carouselData: [
                { imageSrc: '2012/11/DSC02538-1024x768.jpg', caption: '' },
                { imageSrc: '2012/11/DSC02521-1024x768.jpg', caption: '' },
                { imageSrc: '2012/11/DSC02524-1024x768.jpg', caption: '' },
                { imageSrc: '2012/11/DSC02526-1024x768.jpg', caption: '' },
                { imageSrc: '2012/11/DSC02532-1024x768.jpg', caption: '' }
            ],
        },
        {

            id: 18,
            title: "Πολυκατοικία στη Χείλωνος Πατρέως, Πάτρα",
            description: `Κτίριο πανταχόθεν ελεύθερο με πρόσοψη σε τρείς δρόμους.
            Η πολυκατοικία βρίσκεται επί των οδών Μπουκαούρη και Χείλωνος Πατρέως, στην Πάτρα.` ,
            imageUrl: "2012/11/xeilonos0.jpg",
            carouselData: [
                { imageSrc: '2012/11/xeilonos0-300x187.jpg', caption: '' },
                { imageSrc: '2012/11/xeilonos1-239x300.jpg', caption: '' },
                { imageSrc: '2012/11/xeilonos2-239x300.jpg', caption: '' },
                { imageSrc: '2012/11/xeilonos3-237x300.jpg', caption: '' },
                { imageSrc: '2012/11/xeilonos4-238x300.jpg', caption: '' },
                { imageSrc: '2012/11/xeilonos5-300x225.jpg', caption: '' },
            ],
        },
        {
            id: 19,
            title: "Πολυκατοικία στην Καρατζά, Πάτρα",
            description: `Πενταώροφη πολυκατοικία στην Άνω Πόλη Πατρών.
            Η πολυκατοικία βρίσκεται επί των οδών Μπουκαούρη και Καρατζά στην Πάτρα.` ,
            imageUrl: "2012/11/DSC01165-1024x768.jpg",
            carouselData: [
                { imageSrc: '2012/11/DSC01165-1024x768.jpg', caption: '' },
                { imageSrc: '2012/11/DSC01570-768x1024.jpg', caption: '' },
                { imageSrc: '2012/11/DSC01171-768x1024.jpg', caption: '' },
                { imageSrc: '2012/11/DSC01169-1024x768.jpg', caption: '' },
                { imageSrc: '2012/11/DSC01168-768x1024.jpg', caption: '' },
                { imageSrc: '2012/11/DSC01164-768x1024.jpg', caption: '' },
                { imageSrc: '2012/11/DSC01148-768x1024.jpg', caption: '' },
            ],
        },
        {
            id: 20,
            title: "Πολυκατοικία στη Βάρναλη, «Εθνικό Στάδιο» Πάτρας",
            description: `Δείγμα μοντέρνας αρχιτεκτονικής κοντά στο Εθνικό στάδιο.
            Η πολυκατοικία βρίσκεται  επί των οδών Βάρναλη και Μ. Παξινού στην περιοχή «Εθνικό Στάδιο» Πάτρας.` ,
            imageUrl: "2012/11/DSC03282-1024x768.jpg",
            carouselData: [
                { imageSrc: '2012/11/DSC03282-1024x768.jpg', caption: '' },
                { imageSrc: '2012/11/DSC03284-1024x768.jpg', caption: '' },
                { imageSrc: '2012/11/DSC03285-768x1024.jpg', caption: '' },
                { imageSrc: '2012/11/DSC03287-1024x768.jpg', caption: '' }
            ],
        },
        {

            id: 21,
            title: "Πολυκατοικία στον Άγιο Γεώργιο Λάγγουρα Πάτρας",
            description: `Τριώροφη πολυκατοικία με πυλωτή και στέγη κοντά στο Παμπελοποννησιακό Στάδιο Πατρών.
            Η πολυκατοικία βρίσκεται επί των οδών Καλαβρύτων & Παρ. Πατρών Κλάους 26  στην περιοχή Άγιο Γεώργιο Λάγγουρα Πάτρας.` ,
            imageUrl: "2012/11/DSC00565-1024x768.jpg",
            carouselData: [
                { imageSrc: '2012/11/DSC00565-1024x768.jpg', caption: '' },
                { imageSrc: '2012/11/DSC00564-1024x768.jpg', caption: '' },
                { imageSrc: '2012/11/DSC00566-1024x768.jpg', caption: '' },
                { imageSrc: '2012/11/DSC00567-1024x768.jpg', caption: '' }
            ],
        },
        {
            id: 22,
            title: "Πολυκατοικία στον Άγιο Γεώργιο Λάγγουρα Πάτρας",
            description: `Πολυκατοικία επί των οδών Καλαβρύτων & Κλάους 34 (Αγ. Γεώργιος Λάγγουρα).
            Η πολυκατοικία βρίσκεται επί των οδών Καλαβρύτων &  Πατρών Κλάους 34 στην περιοχή Άγιο Γεώργιο Λάγγουρα Πάτρας.` ,
            imageUrl: "2012/11/DSC005611-1024x768.jpg",
            carouselData: [
                { imageSrc: '2012/11/DSC005611-1024x768.jpg', caption: '' },
                { imageSrc: '2012/11/DSC00562-1024x768.jpg', caption: '' },
                { imageSrc: '2012/11/DSC00561-1024x768.jpg', caption: '' },
            ],
        },
        {
            id: 26,
            title: "Πολυκατοικία στη Νοταρά, Αγυιά",
            description: `Πρωτοποριακό κτίριο με χαρακτηριστικό του, τους έντονους κυκλικούς εξώστες.
            Η πολυκατοικία βρίσκεται επί των οδών Νοταρά & Αγυιάς.  `,
            imageUrl: "2012/11/notara11-300x187.jpg",
            carouselData: [
                { imageSrc: '2012/11/notara11-300x187.jpg', caption: '' },
                { imageSrc: '2012/11/notara1-300x187.jpg', caption: '' },
                { imageSrc: '2012/11/notara2-300x205.jpg', caption: '' },
            ],
        },
        {
            id: 46,
            title: "Τριώροφη πολυκατοικία με σοφίτα και δώμα στα Ιωάννινα ",
            description: "Τριώροφη πολυκατοικία με σοφίτα και δώμα στα Ιωάννινα. ",
            imageUrl: "2024/04/polykatoikia/20220629_090144.jpg",
            carouselData: [
                { imageSrc: '2024/04/polykatoikia/20220629_090144.jpg', caption: '' },
                { imageSrc: '2024/04/polykatoikia/20220629_090151.jpg', caption: '' },
                { imageSrc: '2024/04/polykatoikia/20220629_090221.jpg', caption: '' },


            ],
        },
        {
            id: 23,
            title: "Πολυκατοικία στην Εγλυκάδα Πάτρας",
            description: `Τετραώροφο κτίριο με πυλωτή με πανταχόθεν ελεύθερο σύστημα, πράσινο και ανοικτό ορίζοντα.
            Η πολυκατοικια βρίσκεται επί της οδού Πιττακού, στην Εγλυκάδα, στην Πάτρα.  ` ,
            imageUrl: "2012/11/DSC00547-1024x768.jpg",
            carouselData: [
                { imageSrc: '2012/11/DSC00547-1024x768.jpg', caption: '' },
                { imageSrc: '2012/11/DSC00548-1024x768.jpg', caption: '' },
                { imageSrc: '2012/11/DSC00552-1024x768.jpg', caption: '' },
            ],
        },
        {
            id: 24,
            title: "Πολυκατοικία στο κέντρο της Πάτρας",
            description: `Πολυκατοικία στο ιστορικό κέντρο της Πάτρας.
            Η πολυκατοικια βρίσκεται επί της οδού Πιττακού, στην Εγλυκάδα, στην Πάτρα.  ` ,
            imageUrl: "2012/11/DSC00544-768x1024.jpg",
            carouselData: [
                { imageSrc: '2012/11/DSC00544-768x1024.jpg', caption: '' },
                { imageSrc: '2012/11/DSC00543-768x1024.jpg', caption: '' },
                { imageSrc: '2012/11/DSC00545-768x1024.jpg', caption: '' },
            ],
        },
        {
            id: 25,
            title: "Πολυκατοικία στην Πάτρα",
            description: `Κατασκευή πολυκατοικίας στο κέντρο της Πάτρας. Η πολυκατοικία βρίσκεται επί των οδών Ερενστρώλε και Μπουκαούρη.  `,
            imageUrl: "2012/11/IMGP1875-1024x768.jpg",
            carouselData: [
                { imageSrc: '2012/11/IMGP1875-1024x768.jpg', caption: '' },
                { imageSrc: '2012/11/IMGP1881-1024x768.jpg', caption: '' },
                { imageSrc: '2012/11/IMGP1878-1024x768.jpg', caption: '' },
                { imageSrc: '2012/11/IMGP18731-1024x768.jpg', caption: '' },
            ],
        },
        {
            id: 27,
            title: "Πολυκατοικία στη Φωκαίας, Πάτρα",
            description: `Τυπική πολυκατοικία δεκαετίας του 1990.
            Η πολυκατοικία βρίσκεται επί της οδού Φωκαίας, στην Πάτρα.  `,
            imageUrl: "2012/11/DSC005591-1024x768.jpg",
            carouselData: [
                { imageSrc: '2012/11/DSC005591-1024x768.jpg', caption: '' },
                { imageSrc: '2012/11/DSC00557-1024x768.jpg', caption: '' },
                { imageSrc: '2012/11/DSC00559-1024x768.jpg', caption: '' },
            ],
        },
        {
            id: 28,
            title: "Πολυκατοικία στη Σμύρνης, Πάτρα",
            description: `Τυπική πολυκατοικία της δεκαετίας του 1990 στην οδό Σμύρνης στη Πάτρα. `,
            imageUrl: "2012/11/DSC00555-1024x768.jpg",
            carouselData: [
                { imageSrc: '2012/11/DSC00555-1024x768.jpg', caption: '' },
                { imageSrc: '2012/11/DSC00554-1024x768.jpg', caption: '' },
                { imageSrc: '2012/11/DSC00556-1024x768.jpg', caption: '' },
            ],
        },

        // Add more apartment building projects here
    ],

    commercialBuildings: [
        {
            id: 47,
            title: " Επαγγελματικό Εργαστήριο στο Μιντιλόγλι",
            description: ` Επαγγελματικό Εργαστήριο στο Μιντιλόγλι.  `,
            imageUrl: "2023/09/mitilogli/photo1.jpg",
            carouselData: [
                { imageSrc: '2023/09/mitilogli/photo1.jpg', caption: '' },
                { imageSrc: '2023/09/mitilogli/photo2.jpg', caption: '' },
            ],
        },
        {
            id: 29,
            title: "Super Market στην Πάτρα",
            description: "Μεταλλικό κτίριο για χρήση υπεραγοράς.",
            imageUrl: "2012/11/DSC01035-1024x768.jpg",
            carouselData: [
                { imageSrc: '2012/11/DSC01035-1024x768.jpg', caption: '' },
                { imageSrc: '2012/11/DSC01037-1024x768.jpg', caption: '' },
                { imageSrc: '2012/11/DSC01039-1024x768.jpg', caption: '' },
                { imageSrc: '2012/11/DSC01047-1024x768.jpg', caption: '' },

                // Add carousel data for commercial buildings here
            ],
        },
        {
            id: 31,
            title: "Εκθεση ειδών υγιεινής και πλακιδίων στην Πάτρα",
            description: `Μοντέρνο κτίριο οπλισμένου σκυροδέματος εκθεσιακού χώρου στη Πάτρα ιδιαίτερης αισθητικής αντίληψης.
            `,
            imageUrl: "2012/11/DSC01992-1024x768.jpg",
            carouselData: [
                { imageSrc: '2012/11/DSC01992-1024x768.jpg', caption: '' },
                { imageSrc: '2012/11/DSC01994-1024x768.jpg', caption: '' },
                { imageSrc: '2012/11/DSC01995-1024x768.jpg', caption: '' },
                { imageSrc: '2012/11/DSC01996-768x1024.jpg', caption: '' },
                { imageSrc: '2012/11/DSC01998-1024x768.jpg', caption: '' },
                { imageSrc: '2012/11/DSC01999-768x1024.jpg', caption: '' },
                { imageSrc: '2012/11/DSC02001-768x1024.jpg', caption: '' },
                { imageSrc: '2012/11/DSC02003-768x1024.jpg', caption: '' },
                { imageSrc: '2012/11/DSC02004-1024x768.jpg', caption: '' },
                { imageSrc: '2012/11/DSC02007-1024x768.jpg', caption: '' },

                // Add carousel data for commercial buildings here
            ],
        },
        {
            id: 32,
            title: "Εγκαταστάσεις της TOYOTA στον Πύργο",
            description: `Εκθεσιακός χώρος – Συνεργείο μικτής κατασκευής, μεταλλικού κτιρίου και οπλισμένου σκυροδέματος.
            `,
            imageUrl: "2012/11/toyota-pyrgos0-1024x768.jpg",
            carouselData: [
                { imageSrc: '2012/11/toyota-pyrgos0-1024x768.jpg', caption: '' },
                { imageSrc: '2012/11/toyota-pyrgos1-1024x768.jpg', caption: '' },
                { imageSrc: '2012/11/toyota-pyrgos2-1024x768.jpg', caption: '' },
                { imageSrc: '2012/11/toyota-pyrgos3-1024x768.jpg', caption: '' },
                { imageSrc: '2012/11/toyota-pyrgos4-1024x768.jpg', caption: '' },
                { imageSrc: '2012/11/toyota-pyrgos5-1024x768.jpg', caption: '' },

                // Add carousel data for commercial buildings here
            ],
        },
        {
            id: 33,
            title: "Εγκαταστάσεις της TOYOTA στην Πάτρα",
            description: `Στην είσοδο της πόλης των Πατρών ένα πρωτοποριακό κτίριο που συμβαδίζει με τα ευρωπαϊκά πρότυπα της Τοyota.
            Ο επαγγελματικός χώρος βρίσκεται στην είσοδο της πόλης των Πατρώ.
            `,
            imageUrl: "2012/11/toyota-patra-1-294x184.jpg",
            carouselData: [
                { imageSrc: '2012/11/toyota-patra-1-294x180.jpg', caption: '' },
                { imageSrc: '2012/11/toyota-patra-2-150x150.jpg', caption: '' },
                { imageSrc: '2012/11/toyota-patra-3-150x150.jpg', caption: '' },
            ],
        },
        {
            id: 30,
            title: "Σύγχρονα γραφεία",
            description: `Σύγχρονα γραφεία εταιρίας ανάπτυξης μελετών, δημοσκοπήσεων, call-center.`,
            imageUrl: "2012/11/P4280013-1024x768.jpg",
            carouselData: [
                { imageSrc: '2012/11/P4280013-1024x768.jpg', caption: '' },
                { imageSrc: '2012/11/P4280043-1024x768.jpg', caption: '' },
                { imageSrc: '2012/11/P4280038-1024x768.jpg', caption: '' },
                { imageSrc: '2012/11/P4280034-1024x768.jpg', caption: '' },
                { imageSrc: '2012/11/P4280036-1024x768.jpg', caption: '' },
                { imageSrc: '2012/11/P4280020-1024x768.jpg', caption: '' },

                // Add carousel data for commercial buildings here
            ],
        },
      

       
        // Add more commercial building projects here
    ],
    publicInterestBuildings: [
        {
            id: 133,
            title: "Ανακαίνιση του Α.Τ. Ευπαλίου",
            description: `Ανακαίνιση του Α.Τ. Ευπαλίου.
                `,
            imageUrl: "2024/04/ATEupaleio/IMG_20240409_075859.jpg",
            carouselData: [
                { imageSrc: '2024/04/ATEupaleio/IMG_20240409_075859.jpg', caption: '' },
                { imageSrc: '2024/04/ATEupaleio/IMG_20240409_075843.jpg', caption: '' }
            ],
        },
        {
            id: 148,
            title: "Ανακαίνιση  του Α.Τ. Γαβαλούς",
            description: `Ανακαίνιση  του Α.Τ. Γαβαλούς.
                `,
            imageUrl: "2023/09/gavalous/20230504_122636.jpg",
            carouselData: [
                { imageSrc: '2023/09/gavalous/20230504_122610.jpg', caption: '' },
                { imageSrc: '2023/09/gavalous/20230504_122612.jpg', caption: '' },
                { imageSrc: '2023/09/gavalous/20230504_122707.jpg', caption: '' },
                { imageSrc: '2023/09/gavalous/20230504_122628.jpg', caption: '' },
                { imageSrc: '2023/09/gavalous/20230504_122636.jpg', caption: '' },
                { imageSrc: '2023/09/gavalous/IMG_20230330_081154.jpg', caption: '' },
                { imageSrc: '2023/09/gavalous/IMG-2d22de94e458456d3e404f69679c1dc6-V.jpg', caption: '' },
                { imageSrc: '2023/09/gavalous/IMG-27c73384794f722ae01e85f8c1486676-V.jpg', caption: '' },

            ],
        },
        {
            id: 36,
            title: "Ι.Ν. Αγ. Αντωνίου Κρύα Ιτεών",
            description: `Ιερός Ναός Αγίου Αντωνίου περιοχή Κρύα Ιτεών στη Πάτρα, εκκλησιαστικού ρυθμού σταυροειδής βασιλική.
            Η εκκλησία βρίσκεται στην περιοχή Κρύα Ιτεών στη Πάτρα.
                `,
            imageUrl: "2023/09/krya/20170905_084127.jpg",
            carouselData: [
                { imageSrc: '2023/09/krya/20170905_084127.jpg', caption: '' },
                { imageSrc: '2023/09/krya/20170905_084147.jpg', caption: '' },
                { imageSrc: '2023/09/krya/20170905_084307.jpg', caption: '' },
                { imageSrc: '2023/09/krya/20170905_084436.jpg', caption: '' },

            ],
        },
        {
            id: 85,
            title: "Ι.Ν. Αγ. Ιωάννου και Αγ. Αποστόλων, Ζαβλάνι ",
            description: `Ιερός Ναός Αγίου Ιωάννου και Αγίων Αποστόλων περιοχή Ζαβλάνι Πατρας.  `,
            imageUrl: "2023/09/zavlani/fvs7777.jpg",
            carouselData: [
                { imageSrc: '2023/09/zavlani/fvs7777.jpg', caption: '' },
                { imageSrc: '2023/09/zavlani/fff5555.jpg', caption: '' },
                { imageSrc: '2023/09/zavlani/ap3.jpg', caption: '' },
               // { imageSrc: '2023/09/zavlani/20180327_103956.jpg', caption: '' },
               //{ imageSrc: '2023/09/zavlani/20180327_104102.jpg', caption: '' },
               // { imageSrc: '2023/09/zavlani/20180327_104153.jpg', caption: '' },

            ],
        },
        {
            id: 34,
            title: "Εκκλησιαστικό Λύκειο Πατρών",
            description: `Εγκαταστάσεις Νέου Εκκλησιαστικού Λυκείου Πατρών και Οικοτροφείο στη Παραλία Πατρών. 
            Κατασκευή των Εγκαταστάσεων του Νέου Εκκλησιαστικού Λυκείου Πατρών καθώς και του Οικοτροφείο στη Παραλία Πατρών.
            `,
            imageUrl: "2012/11/sxoleio-294x146.jpg",
            carouselData: [
                { imageSrc: '2012/11/sxoleio-640x400.jpg', caption: '' },
                { imageSrc: '2012/11/IMGP1200-1024x768.jpg', caption: '' },
                { imageSrc: '2012/11/IMGP1119-1024x768.jpg', caption: '' },
                { imageSrc: '2012/11/IMGP1116-1024x768.jpg', caption: '' },
                { imageSrc: '2012/11/2011-11-10-11.38.36-1024x768.jpg', caption: '' },
                { imageSrc: '2012/11/img_6652.jpg', caption: '' },
                { imageSrc: '2012/11/2011-11-10-11.39.52-1024x768.jpg', caption: '' },
                { imageSrc: '2012/11/2011-11-10-11.39.04-1024x768.jpg', caption: '' },
                { imageSrc: '2012/11/2011-11-10-11.38.49-1024x768.jpg', caption: '' },
                { imageSrc: '2012/11/img_66891.jpg', caption: '' },
                { imageSrc: '2012/11/img_66884.jpg', caption: '' },
                { imageSrc: '2012/11/img_66880.jpg', caption: '' },
                { imageSrc: '2012/11/img_66879.jpg', caption: '' },
                { imageSrc: '2012/11/img_66768.jpg', caption: '' },
                { imageSrc: '2012/11/img_66766.jpg', caption: '' },
                { imageSrc: '2012/11/img_66761.jpg', caption: '' },
                { imageSrc: '2012/11/img_6659.jpg', caption: '' },
                { imageSrc: '2012/11/IMGP1173-1024x768.jpg', caption: '' },
            ],
        },
        {
            id: 35,
            title: "Ι.Ν. Αγ. Ιωάννου Θεολόγου ",
            description: `Αναστήλωση Ιερού Ναού Αγίου Ιωάννου Θεολόγου με σύγχρονες επεμβατικές μεθόδους χωρίς να αλλοιωθούν τα ιστορικά χαρακτηριστικά του Ναού.
            `,
            imageUrl: "2012/11/IMGP18641-1024x768.jpg",
            carouselData: [
                { imageSrc: '2012/11/IMGP1842-1024x768.jpg', caption: '' },
                { imageSrc: '2012/11/IMGP1843-1024x768.jpg', caption: '' },
                { imageSrc: '2012/11/IMG_20121010_103046-1024x768.jpg', caption: '' },
                { imageSrc: '2012/11/IMG_20121010_105525.jpg', caption: '' },
                { imageSrc: '2012/11/IMGP1847-1024x768.jpg', caption: '' },
                { imageSrc: '2012/11/IMGP1851-1024x768.jpg', caption: '' },
                { imageSrc: '2012/11/IMGP1857-1024x768.jpg', caption: '' },
                { imageSrc: '2012/11/IMGP1861-1024x768.jpg', caption: '' },
                { imageSrc: '2012/11/IMGP18641-1024x768.jpg', caption: '' },
                { imageSrc: '2012/11/IMGP1866-1024x768.jpg', caption: '' },
            ],
        },
      
        {
            id: 37,
            title: "Ι.Ν. Αγ. Χαραλάμπους Οβρυά Πατρών",
            description: `Ιερός Ναός Αγίου Χαραλάμπους Οβρυά Πατρών, εκκλησιαστικού ρυθμού σταυροειδής βασιλική.
            Η εκκλησία βρίσκεται στην περιοχή Κρύα Ιτεών στη Πάτρα.
                `,
            imageUrl: "2012/11/image002-1024x581.jpg",
            carouselData: [
                { imageSrc: '2012/11/image002-1024x581.jpg', caption: '' },
                { imageSrc: '2012/11/image010-1024x603.jpg', caption: '' },
                { imageSrc: '2012/11/image012-1024x596.jpg', caption: '' },
            ],
        },
        {
            id: 38,
            title: "Πνευματικό Κέντρο Ι.Ν. Παναγίας Αλεξιώτισσας",
            description: `Νέο πνευματικό ενοριακό κέντρο Ιερού Ναού Παναγίας Αλεξιώτισσας στη Πάτρα.
            Η εκκλησία βρίσκεται στην περιοχή Κρύα Ιτεών στη Πάτρα.
                `,
            imageUrl: "2012/11/DSC00539-1024x768.jpg",
            carouselData: [
                { imageSrc: '2012/11/DSC00539-1024x768.jpg', caption: '' },
                { imageSrc: '2012/11/DSC00513-1024x768.jpg', caption: '' },
                { imageSrc: '2012/11/DSC00538-1024x768.jpg', caption: '' },
                { imageSrc: '2012/11/DSC00534-1024x768.jpg', caption: '' },

            ],
        },
       
        // Add more religious building projects here
    ],
};

export default projectsData;
