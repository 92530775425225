import React, { useEffect, useState } from 'react';
import { Nav, Navbar, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';


function NavbarMenu() {

  const [activeLink, setActiveLink] = useState('/');


  useEffect(() => {
    // Get the current path from the URL on initial load
    const currentPath = window.location.pathname;

    setActiveLink(currentPath);
  }, []);

  const handleNavLinkClick = (path) => {
    setActiveLink(path);
  };

  return (
    <>
        {/* <Navbar
      style={{ borderBottom: '2px solid #1f334b', backgroundColor: '#0C3348', minHeight: '30px' }}
      className="text-white"
      expand="lg"
    >
      <Container className="justify-content-start" style={{ height: 30 }}>
        <Navbar.Brand style={{ cursor: 'pointer' }}>
          <i className="bi bi-house-fill fs-10 me-1" style={{ fontSize: '14px', fontWeight: 'bold', color: 'white' }} >  </i> <span style={{ fontSize: '12px', color: 'white'}}> Καρατζά 41, Πάτρα 26 223 </span>
        </Navbar.Brand>
        <Navbar.Brand style={{ cursor: 'pointer' }}>
          <i className="bi bi-telephone-fill fs-10 me-1" style={{ fontSize: '14px', fontWeight: 'bold', color: 'white' }} >  </i> <span style={{ fontSize: '12px', color: 'white' }}> 2610 313158</span>
        </Navbar.Brand>
        <Navbar.Brand style={{ cursor: 'pointer' }}>
          <i className="bi bi-envelope-fill fs-10 me-1 " style={{ fontSize: '14px', fontWeight: 'bold', color: 'white' }}> </i> <span style={{ fontSize: '12px', color: 'white'}}> info@kaouris.gr</span>
        </Navbar.Brand>
      </Container>
    </Navbar> */}
    
    {/* Second Layer Navbar */}
    <Navbar
      style={{ borderBottom: '2px solid #DEE2E6', minHeight: '80px' , backgroundColor: '#1F334B', padding: 0}}
      // className="bg-light-subtle bg-gradient my-navbar"
      className="text-white"

      expand="lg"
    >
      <Container >
        <Navbar.Brand href="/" style={{ margin: 0}}>
          <img
            src="/logo2.jpg"
            alt="Kaouris Logo"
            width="240"
            height="70"
          />
        </Navbar.Brand>
        <Navbar.Toggle style={{ margin: 0}}  aria-controls="navbar" />
        <Navbar.Collapse style={{ margin: 0}} id="navbar" className="justify-content-end">
          <Nav className="mr-auto" style={{ margin: 0}}>
            <Link className={activeLink === '/'?'active':''} to="/" style={{ color: 'white', textDecoration: 'none', marginRight: 10 }}  onClick={() => handleNavLinkClick('/')}>ΑΡΧΙΚΗ</Link>
            <Link className={activeLink === '/about'?'active':''} to="/about" style={{ color: 'white', textDecoration: 'none' , marginRight: 10 }}  onClick={() => handleNavLinkClick('/about')} >ΠΟΙΟΙ ΕΙΜΑΣΤΕ</Link>
            <Link className={activeLink === '/services'?'active':''} to="/services" style={{ color: 'white', textDecoration: 'none' , marginRight: 10 }}  onClick={() => handleNavLinkClick('/services')} >ΥΠΗΡΕΣΙΕΣ</Link>
            <Link className={activeLink === '/projects'?'active':''} to="/projects" style={{ color: 'white', textDecoration: 'none', marginRight: 10   }}  onClick={() => handleNavLinkClick('/projects')} >ΤΑ ΕΡΓΑ ΜΑΣ</Link>
            <Link className={activeLink === '/sales'?'active':''}  style={{ color: 'white', opacity:0.5 ,  textDecoration: 'none' , marginRight: 10 }} disabled   >ΠΩΛΗΣΕΙΣ/ΕΝΟΙΚΙΑΣΕΙΣ</Link>

            <Link className={activeLink === '/contact'?'active':''} to="/contact" style={{ color: 'white',  textDecoration: 'none'  }}  onClick={() => handleNavLinkClick('/contact')} >ΕΠΙΚΟΙΝΩΝΙΑ</Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>

    </>
  )
}

export default NavbarMenu;

