import React from 'react';
import { Carousel } from 'react-bootstrap';

function CarouselItemModal({ data }) {
    return (
      <Carousel style={{
        maxHeight: "20vw",
        maxWidth: "25vw",
        height: '20vw',
        width: "25vw",
        minHeight: "200px",
        minWidth: "250px",
        borderRadius: "10px",

    }} >
        {data &&
          data.map((item) => (
            <Carousel.Item    style={{
                maxHeight: "20vw",
                maxWidth: "25vw",
                height: '20vw',
                width: "25vw",
                minHeight: "200px",
                minWidth: "250px",
                borderRadius: "10px",

            }}
            key={item.id} interval={2000}>
              <img
                src={item.imageSrc}
                style={{
                    maxHeight: "20vw",
                    maxWidth: "25vw",
                    minHeight: "200px",
                    height: '20vw',
                    width: "25vw",
                    minWidth: "250px",
                    cursor: "pointer",
                    borderRadius: "10px",
                    transition: "transform 0.2s",
                  }}                alt="Kaouris Logo"
              />
              <Carousel.Caption className="d-none d-md-block" style={{ width: '44%', margin: '0 auto' }}>
                <h3 style={{  textShadow: "6px 6px 12px  rgba(0,0,0,0.8)", }}>{item.caption}</h3>
              </Carousel.Caption>
            </Carousel.Item>
          ))}
      </Carousel>
    );
  }  
export default CarouselItemModal ;