import React from 'react';
import { Modal, Button , Dropdown} from 'react-bootstrap';
import CarouselItemModal from './CarouselItemModal';

function CarouselModal({ title, content, showModal, closeModal ,carouselData}) {
  return (
    <Modal show={showModal} onHide={closeModal} dialogClassName="custom-modal modal-dialog-centered" >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='d-flex w-100 justify-content-center'>

        { carouselData &&  carouselData.length > 0 && <CarouselItemModal  data={carouselData} />  } 
        </div>

        <Dropdown.Divider className='mt-2 mb-2'></Dropdown.Divider>
        {content}
      </Modal.Body>
      <Modal.Footer>
        <Button style = {{ backgroundColor: '#0c3348' ,  border: 'none', }} onClick={closeModal}>
          ΚΛΕΙΣΙΜΟ
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default CarouselModal;
