import React, { useEffect, useRef, useState } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';


function Progressbar({ value, text, textNum }) {
  const [animatedValue, setAnimatedValue] = useState(0);
  const [animatedTextNum, setAnimatedTextNum] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const progressbarRef = useRef(null);


  useEffect(() => {
    const options = {
      root: null, // Use the viewport as the root
      rootMargin: '0px', // No margin
      threshold: 0.5, // Trigger when 50% of the element is visible
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(progressbarRef.current);
        }
      });
    }, options);

    observer.observe(progressbarRef.current);

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    if (isVisible) {
      let progress = 0;
      let num = 0;
      let intervalDuration = textNum > 300 ? 5 : 10; // Adjust interval duration here
      let increment = 1;
      let incrementText = textNum > 300 ? 5 : 1;

  
      const interval = setInterval(() => {
        if (progress < value) {
          progress += increment;
          setAnimatedValue(progress);
        }
  
        if (num < textNum) {
          num += incrementText;
          setAnimatedTextNum(num);
        }
  
        if (progress >= value && num >= textNum) {
          clearInterval(interval);
        }
      }, intervalDuration);
  
      return () => clearInterval(interval);
    }
  }, [isVisible, value, textNum]);

  return (
    <div
      ref={progressbarRef}
      style={{
        width: '150px',
        minHeight: '200px',
        margin: '10px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CircularProgressbar
        className="circular-progress-bar" // Apply the CSS class for transition
        styles={buildStyles({
          pathColor: '#4c5c6f',
          textColor: 'black',
        })}
        value={animatedValue} // Use animatedValue instead of value
        text={`${animatedTextNum}`} // Use animatedTextNum instead of textNum
      />
      <span style={{ fontSize: '22px', fontStyle: 'italic', textAlign: 'center', minHeight: '150px', marginTop: '10px' }}>{text}</span>
    </div>
  );
}

export default Progressbar;